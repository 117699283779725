/* eslint-disable react-hooks/exhaustive-deps */
import './datatable.scss'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Chip, IconButton } from '@mui/material'
import CustomColumnMenu from '../datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../datagrid/CustomLoadingOverlay'

const DatatableClientConsumptions = ({ consumptions, isLoading, refetch }) => {
  const { t } = useTranslation(['common'])

  const columns = [
    {
      field: '_id',
      headerName: 'Id',
      minWidth: 200,
      sortable: false,
      flex: 1,
    },
    {
      field: 'tenant',
      headerName: t('common:tenant'),
      minWidth: 120,
      sortable: false,
      flex: 1,
    },
    {
      field: 'product',
      headerName: t('common:product'),
      minWidth: 150,
      renderCell: (params) => {
        return params.row.product ? <Chip size="small" label={params.row.product} /> : '---'
      },
      sortable: false,
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('common:type'),
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: 'action',
      headerName: t('common:action'),
      minWidth: 120,
      sortable: false,
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('common:status'),
      renderCell: (params) => {
        if (!params.row.status) return '---';

        return params.row.status === 'SUCCESS' ? (
          <Chip size="small" label={params.row.status} color="success" />
        ) : (
          <Chip size="small" label={params.row.status} color="error" />
        )
      },
      minWidth: 120,
      sortable: false,
      flex: 1,
    },
  ]

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Box className="filter-bar">
        <IconButton sx={{ marginLeft: 'auto' }} onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
          <RefreshIcon color="action" />
        </IconButton>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={Array.isArray(consumptions) ? consumptions : []}
        columns={columns}
        slots={{
          columnMenu: CustomColumnMenu,
          loadingOverlay: CustomLoadingOverlay,
        }}
        hideFooter={true}
        autoHeight
        loading={isLoading}
      />
    </Box>
  )
}

export default DatatableClientConsumptions
