import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import { Paper, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { useTranslation } from 'react-i18next'

import { useGetCaseStatisticsQuery } from '../../../features/cases/caseSlice'
import { CaseStatus } from '../../case-management/case.status'
import Stat from './Stat'

const iconByStatus = {
  CLOSED_WITHOUT_ACTIONS: CheckCircleIcon,
  CLOSED_WITH_ACTIONS: CheckCircleIcon,
  REVIEW_IN_PROGRESS: HourglassBottomIcon,
  PENDING: HourglassBottomIcon,
  REVIEW_FINISHED: HourglassBottomIcon,
}

const CasesStats = () => {
  const { period } = useSelector((state) => state.statistics)

  const { t } = useTranslation(['messages', 'common'])

  const { data: statisticsResponse } = useGetCaseStatisticsQuery({ type: 'by_status', period })
  const { data: statistics } = statisticsResponse || { data: [] }

  const total = useMemo(() => statistics.reduce((acc, item) => acc + item.total || 0, 0), [statistics])
  const totalByStatus = useMemo(
    () =>
      statistics.reduce((acc, item) => {
        acc[item._id] = item.total
        return acc
      }, {}),
    [statistics],
  )

  return (
    <Grid xs={12} component={Paper}>
      <Grid xs={12}>
        <Typography variant="h4">{t('messages:statistics.cases.title.resume')}</Typography>
      </Grid>
      <Grid container xs={12}>
        <Grid xs={2}>
          <Stat total={total} title="Total" />
        </Grid>
        {CaseStatus.map((status) => {
          const Icon = iconByStatus[status]
          const total = totalByStatus[status] || 0
          return (
            <Grid key={status} xs={2}>
              <Stat total={total} title={t(`messages:statistics.cases.states.${status}`)} icon={<Icon />} />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default CasesStats
