import composeClasses from '@mui/utils/composeClasses'
import {
  getDataGridUtilityClass,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

const useUtilityClasses = (ownerState) => {
  const { classes } = ownerState

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  }

  return composeClasses(slots, getDataGridUtilityClass, classes)
}

const CustomGridTreeDataGroupingCell = ({ id, field, formattedValue, row, rowNode, country }) => {
  const { t } = useTranslation(['common', 'messages'])
  const rootProps = useGridRootProps()
  const apiRef = useGridApiContext()
  const classes = useUtilityClasses(rootProps)
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector)
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0
  const Icon = rowNode.childrenExpanded ? rootProps.slots.treeDataCollapseIcon : rootProps.slots.treeDataExpandIcon

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <div className={classes.root} style={{ marginLeft: rowNode.depth * 8 }}>
      <div className={classes.toggle}>
        {filteredDescendantCount > 0 && (
          <rootProps.slots.baseIconButton
            size="small"
            onClick={handleClick}
            tabIndex={-1}
            aria-label={
              rowNode.childrenExpanded
                ? apiRef.current.getLocaleText('treeDataCollapse')
                : apiRef.current.getLocaleText('treeDataExpand')
            }
            {...rootProps?.slotProps?.baseIconButton}
          >
            <Icon fontSize="inherit" />
          </rootProps.slots.baseIconButton>
        )}
      </div>
      {rowNode?.type === 'group' && <span>{formattedValue === undefined ? rowNode.groupingKey : formattedValue}</span>}
      {rowNode?.type === 'leaf' && (
        <span style={{ lineHeight: 1.5 }}>{t(`messages:riskmatrix.attribute.${country}.${row.attribute}`)}</span>
      )}
    </div>
  )
}

export default CustomGridTreeDataGroupingCell
