import { Box, IconButton, TableContainer, TablePagination, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useFindKybSubjectTimelineQuery, useFindKybTimelineQuery } from '../../../../services/guenoApi'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KybChip } from '../../../../components/utilities/KybChip'
import { buildTimeStringFromTimestamp } from '../../../../components/common/time/timeHelper'
import RefreshIcon from '@mui/icons-material/Refresh'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import CustomColumnMenu from '../../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../../components/common/datagrid/CustomLoadingOverlay'

const getStyles = (type) => {
  let color
  let backgroundColor

  switch (type.toLowerCase()) {
    case 'creation':
      backgroundColor = '#4184c3'
      color = '#fafafa'
      break
    case 'complete':
      backgroundColor = '#00B85F'
      color = '#FBFFFC'
      break
    default:
      backgroundColor = '#FF8A00'
      color = '#FBFFFC'
      break
  }

  return { color, backgroundColor }
}

const TimelineTable = ({ id, country, target = 'business' }) => {
  const { t } = useTranslation(['common'])
  const apiRef = useGridApiRef()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const endpointToCall = target === 'business' ? useFindKybTimelineQuery : useFindKybSubjectTimelineQuery

  const { isFetching, isLoading, refetch, data } = endpointToCall({
    limit: rowsPerPage,
    page,
    id,
    country,
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const columns = [
    {
      field: 'type',
      headerName: t('common:type'),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        const { color, backgroundColor } = getStyles(row.type)
        return <KybChip action={t(`common:${row.type}`)} color={color} backgroundColor={backgroundColor} />
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      flex: 0.7,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Typography variant="text2">
            {t(
              `common:${
                row.status === 'SUCCESS'
                  ? 'ACTION_FINISHED_WITHOUT_ERROR'
                  : row.status === 'ERROR'
                  ? 'ACTION_FINISHED_WITH_ERROR'
                  : row.status
              }`,
            )}
          </Typography>
        )
      },
    },
    {
      field: 'email',
      headerName: t('common:user'),
      flex: 0.8,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return <Typography variant="number">{row.email}</Typography>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      flex: 0.8,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(row.createdAt)}</Typography>
      },
    },
  ]

  const { items: timelines, pagination } = useMemo(() => {
    if (data?.data) {
      return data?.data
    }
    return { items: [], pagination: null }
  }, [data])

  return (
    <TableContainer>
      <Box className="filter-bar">
        <Box style={{ flex: 1 }} />
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TablePagination
            labelRowsPerPage={[]}
            component="div"
            count={pagination?.totalElements || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        <Box style={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
          <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
            <RefreshIcon color="action" />
          </IconButton>
        </Box>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={timelines || []}
        columns={columns}
        apiRef={apiRef}
        slots={{
          columnMenu: CustomColumnMenu,
          loadingOverlay: CustomLoadingOverlay,
        }}
        hideFooter={true}
        autoHeight
        sortingMode="server"
        loading={isLoading || isFetching}
      />
    </TableContainer>
  )
}

export default TimelineTable
