import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'
import { cleanParams } from '../../services/builders'

export const defaultFilters = {
  viewMode: true,
  page: 0,
  limit: 10,
  fromDate: null,
  toDate: '',
  offset: 0,
  emailClient: '',
  email: '',
  status: '',
  roles: [],
}

export const roleSlice = createSlice({
  name: 'role',
  initialState: {
    roles: [],
    filters: {
      ...defaultFilters,
    },
  },
  reducers: {
    updateRoles: (state, action) => {
      state.roles = action.payload
    },
    performLogoutRoles: (state) => {
      state.roles = []
    },
    updateRolesFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

const endpointsRoles = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findRoles: builder.query({
      query: ({ emailClient, viewMode, limit, page, offset, status, roleName }) => {
        const filteredParams = cleanParams({
          viewMode,
          limit,
          page,
          offset,
          status,
          roleName,
        })
        return {
          url: `/admin/roles/emailClient/${emailClient}`,
          params: filteredParams,
        }
      },
    }),
  }),
})

export const { updateRoles, performLogoutRoles, updateRolesFilters } = roleSlice.actions
export const { useFindRolesQuery } = endpointsRoles
export default roleSlice.reducer
