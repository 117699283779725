import { useDispatch, useSelector } from 'react-redux'
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'

import guenoApi from '../../../services/guenoApi'
import { updatePeriod } from '../../../features/statistics/statisticsSlice'

const HeaderPanel = () => {
  const { t } = useTranslation(['messages', 'common'])
  const dispatch = useDispatch()
  const { period } = useSelector((state) => state.statistics)

  const refetch = () => {
    dispatch(guenoApi.util.invalidateTags(['casesStatistics', 'alertsStatistics']))
  }

  const handleChangePeriod = (event) => {
    dispatch(updatePeriod(event.target.value))
  }

  const periods = ['today', 'lastWeek', 'currentMonth', 'lastMonth', 'currentYear', 'lastYear']

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <IconButton onClick={refetch}>
          <RefreshIcon color="action" />
        </IconButton>
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <InputLabel id="demo-simple-select-label">Period</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={period}
            label="Period"
            onChange={handleChangePeriod}
          >
            {periods.map((period) => (
              <MenuItem key={period} value={period}>
                {t(`common:periods.${period}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export default HeaderPanel
