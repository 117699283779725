import Navbar from '../navbar/Navbar'
import './layout.scss'
import '../../../pages/home/home.scss'
import PersistentDrawerLeft from '../sidebar/SwipeableSidebar'
import { Box } from '@mui/material'
import '../../../pages/kyt/transaction/transaction.scss'
import ChatButton from '../floatingChat/FloatingChat'
import { useFindUserMasterQuery } from '../../../services/guenoApi'
import { useSelector } from 'react-redux'

export default function Layout({ children, handleUserLogout, activeServices }) {
  const { emailClient } = useSelector((state) => state.login)
  const { data, isLoading } = useFindUserMasterQuery({ emailClient })
  return (
    <Box className="layout">
      <Box className="sidebar">
        <PersistentDrawerLeft activeServices={activeServices} handleUserLogout={handleUserLogout} />
      </Box>
      <Box className="view-wrapper">
        <Box className="navbar">
          <Navbar handleUserLogout={handleUserLogout} />
        </Box>
        <Box className="view">{children}</Box>
      </Box>
      {/* Botón de chat flotante */}
      {data?.iaChatEnabled && <ChatButton />}
    </Box>
  )
}
