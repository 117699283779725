export const ruleCategories = [
  'acquirers',
  'wallet',
  'bank',
  'psp',
  'psav',
  'crypto',
  'fraud',
  'aml',
  'suspiciousPatterns',
  'collusionOrMultiplicity',
  'geoRisk',
  'inactivity',
  'others',
]
