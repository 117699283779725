import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'
import { cleanParams } from '../../services/builders'

export const defaultAlertsFilters = {
  emailClient: '',
  id: '',
  page: 0,
  limit: 10,
  offset: 0,
  type: 'transactionRule',
  fromDate: null,
  toDate: '',
  status: '',
  timezone: -3,
}

export const generalAlertsSlice = createSlice({
  name: 'generalAlerts',
  initialState: {
    alerts: {},
    consumerUser: [],
    filters: {
      ...defaultAlertsFilters,
    },
  },
  reducers: {
    updateGeneralAlerts: (state, action) => {
      state.alerts = action.payload
    },
    updateConsumerUserGeneralAlert: (state, action) => {
      state.consumerUser = action.payload
    },
    updateGeneralAlertsFilters: (state, filters) => {
      state.filters = filters.payload
    },
    resetGeneralAlertsFilters: (state) => {
      state.filters = {
        ...defaultAlertsFilters,
      }
    },
  },
})

const endpoints = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findGeneralAlerts: builder.query({
      query: ({ emailClient, id, limit, offset, page, fromDate, toDate, type, status }) => {
        const filteredParams = cleanParams({
          emailClient,
          id,
          limit,
          offset,
          page,
          fromDate,
          toDate,
          type,
          status,
        })
        return {
          url: `/generalAlerts`,
          params: filteredParams,
        }
      },
    }),
    updateAlertStatus: builder.mutation({
      query: ({ alertToUpdate, status }) => ({
        url: `/generalAlerts/${alertToUpdate}/status/${status}`,
        method: 'PATCH',
      }),
    }),
    getStatistics: builder.query({
      query: ({ type, period }) => {
        return {
          url: `/generalAlerts/statistics`,
          params: {
            type,
            period,
          },
        }
      },
      providesTags: ['alertsStatistics'],
    }),
  }),
})

export const {
  updateGeneralAlerts,
  updateConsumerUserGeneralAlert,
  updateGeneralAlertsFilters,
  resetGeneralAlertsFilters,
} = generalAlertsSlice.actions

export default generalAlertsSlice.reducer

export const { useFindGeneralAlertsQuery, useUpdateAlertStatusMutation, useGetStatisticsQuery } = endpoints
