import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'

const buildCasesParams = (query = {}) => {
  const { searchText, nationality, timezone = -3 } = query
  const params = {
    ...(searchText && { text: searchText }),
    ...(nationality && { nationality }),
  }
  return params
}

export const defaultKymFilters = {
  fromDate: null,
  toDate: null,
  searchText: '',
  country: '',
}

export const defaultKymParams = {
  page: 0,
  limit: 10,
  offset: 0,
  ...defaultKymFilters,
}

export const kymPersonsSlice = createSlice({
  name: 'kym',
  initialState: {
    filters: {
      ...defaultKymParams,
    },
  },
  reducers: {
    setCurrentNationality(state, action) {
      state.filters.nationality = action.payload
    },
  },
})

const endpoints = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findKymPersons: builder.query({
      query: ({ emailClient, limit, page, offset, sortField, sortDirection, ...query }) => {
        const params = buildCasesParams(query)
        return {
          url: `/kym/persons`,
          params: {
            emailClient,
            limit,
            page,
            offset,
            sortField,
            sortDirection,
            ...params,
          },
        }
      },
      providesTags: ['kymPersons'],
    }),
  }),
})

export default kymPersonsSlice.reducer
export const { setCurrentNationality } = kymPersonsSlice.actions

export const { useFindKymPersonsQuery } = endpoints
