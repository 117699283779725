import { createSlice } from '@reduxjs/toolkit'

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: {
    period: 'today',
  },
  reducers: {
    updatePeriod: (state, action) => {
      state.period = action.payload
    },
  },
})

export const { updatePeriod } = statisticsSlice.actions

export default statisticsSlice.reducer
