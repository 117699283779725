import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Paper, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useGetStatisticsQuery } from '../../../features/generalAlerts/generalAlertsSlice'

const AlertsByRuleId = () => {
  const type = 'by_rule'
  const { t } = useTranslation(['messages', 'common'])

  const { period } = useSelector((state) => state.statistics)
  const { data: statisticsResponse, isFetching, isLoading } = useGetStatisticsQuery({ type, period })
  const { data: statisticsRaw } = statisticsResponse || { data: [] }
  const [sortModel, setSortModel] = useState([{ field: '_id', sort: 'desc' }])

  const statistics = statisticsRaw.map((stat) => ({ ...stat, _id: stat._id || '-' }))

  const columns = [
    { field: '_id', headerName: 'Rule' },
    { field: 'total', headerName: t(`messages:statistics.alerts.states.total`) },
    { field: 'pending', headerName: t(`messages:statistics.alerts.states.pending`) },
    { field: 'reviewing', headerName: t(`messages:statistics.alerts.states.reviewing`) },
    { field: 'closed', headerName: t(`messages:statistics.alerts.states.closed`) },
  ]

  return (
    <Grid component={Paper} container>
      <Grid xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4">{t(`messages:statistics.alerts.title.${type}`)}</Typography>
      </Grid>
      <Grid xs={12}>
        <DataGridPro
          getRowId={(row) => row._id}
          rows={statistics || []}
          columns={columns}
          disableColumnMenu
          hideFooterPagination
          loading={isFetching || isLoading}
          sortModel={sortModel}
          onSortModelChange={(sortModel) => setSortModel(sortModel)}
          hideFooter
          autoHeight
        />
      </Grid>
    </Grid>
  )
}

export default AlertsByRuleId
