import { Box, Button, Fab, Modal, TableContainer, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TableChip } from '../../../utilities/TableChip'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { RulesDrawer } from '../rulesLibrary/RulesDrawer'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { ruleCategories } from '../constants'

const ArchivedRules = ({
  rules,
  isLoading,
  setIsLoading,
  instanceRules,
  setInstanceRules,
  categories,
  setCategories,
}) => {
  const { t } = useTranslation(['common', 'messages', 'rules'])
  const [selectedRule, setSelectedRule] = useState({})
  const [ruleToEdit, setRuleToEdit] = useState({})
  const [open, setOpen] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [actionType, setActionType] = useState('')
  const [message, setMessage] = useState('')

  const handleNext = (rule) => {
    const selected = instanceRules.filter((element) => {
      return element.id === rule
    })

    setSelectedRule(selected[0])
    setOpen(true)
  }

  const handleCopy = (rule) => {
    const selected = instanceRules.filter((element) => {
      return element.id === rule
    })
    setSelectedRule(selected[0])
    setOpen(true)
    setIsCopy(true)
  }

  useEffect(() => {
    if (selectedRule && Object.keys(selectedRule).length !== 0 && rules.length !== 0) {
      const schemaSelected = rules.filter((element) => {
        return element.id === selectedRule?.ruleId
      })
      setRuleToEdit(schemaSelected[0])
    }
  }, [selectedRule, rules])

  const handleAction = () => {
    if (actionType === 'duplicate') {
      handleCopy(selectedRule.id)
      return handleClose()
    }
  }

  const handleClose = () => {
    setModalOpen(false)
    setMessage('')
    setActionType('')
  }

  const columns = [
    {
      field: 'ruleId',
      headerName: t('common:ruleId'),
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return `${params.row.maskId ? params.row.maskId : `${params.row.ruleId} (${params.row.id})`}`
      },
    },
    {
      field: 'ruleName',
      headerName: t('common:ruleName'),
      flex: 1,
      minWidth: 350,
      renderCell: (params) => {
        return <Box>{params.row.ruleNameAlias || params.row.name}</Box>
      },
    },
    {
      field: 'ruleDescriptionAlias',
      headerName: t('common:ruleDescription'),
      flex: 1,
      minWidth: 450,
      renderCell: (params) => {
        return (
          <Box>
            <Typography variant="subtitle3">{params.row.ruleDescriptionAlias ?? '---'}</Typography>
          </Box>
        )
      },
    },
    {
      field: 'action',
      headerName: t('common:action'),
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <TableChip action={params.row.action} noIcon={true} />
      },
      valueGetter: (params) => {
        return params.row.action
      },
    },
    {
      field: 'hitRate',
      headerName: t('common:hitRate'),
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <Typography variant="subtitle1">
            {(params.row.runCount === 0 ? 0 : (params.row.hitCount * 100) / params.row.runCount).toFixed() + '%'}
          </Typography>
        )
      },
      valueGetter: (params) => {
        return (params.row.runCount === 0 ? 0 : (params.row.hitCount * 100) / params.row.runCount).toFixed()
      },
    },

    {
      field: 'actions',
      headerName: t('common:actions'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box className="user-admin-flex">
            <Tooltip title={t('common:seeDetails')}>
              <Button variant="outlinedButton" className="icon-fab" onClick={() => handleNext(params.row.id)}>
                <VisibilityIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t('common:duplicate')}>
              <Button
                variant="outlinedButtonSuccess"
                className="icon-fab"
                onClick={() => {
                  setSelectedRule(params.row)
                  setActionType('duplicate')
                  setMessage(t('messages:rules.duplicateDesc'))
                  setModalOpen(true)
                }}
              >
                <ContentCopyOutlinedIcon />
              </Button>
            </Tooltip>
          </Box>
        )
      },
    },
  ]

  return (
    <Box>
      <Box>
        <Box className="filter-bar" sx={{ justifyContent: 'unset' }}>
          {ruleCategories.map((category) => (
            <Button
              key={category}
              variant={categories.includes(category) ? 'action' : 'label'}
              onClick={() =>
                setCategories((prevState) => {
                  setIsLoading(true)
                  if (prevState.includes(category)) {
                    return prevState.filter((c) => c !== category)
                  }
                  return [...new Set([...prevState, category])]
                })
              }
            >
              {t(`messages:kyt.rules.categories.${category}`)}
            </Button>
          ))}
        </Box>
        <TableContainer>
          <DataGrid
            getRowId={(row) => row.id}
            getRowHeight={() => 'auto'}
            rows={(Array.isArray(instanceRules) && instanceRules?.length > 0 && instanceRules) || []}
            columns={columns}
            hideFooter={true}
            autoHeight={true}
          />
        </TableContainer>
      </Box>
      {instanceRules.length !== 0 && (
        <RulesDrawer
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          selectedRule={selectedRule}
          setSelectedRule={setSelectedRule}
          setOpen={setOpen}
          open={open}
          myRule={true}
          ruleToEdit={ruleToEdit}
          ruleList={instanceRules}
          setInstanceRules={setInstanceRules}
          isCopy={isCopy}
          setIsCopy={setIsCopy}
          handleCopy={handleCopy}
        />
      )}

      <Modal open={modalOpen} onClose={handleClose}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{`${t(`common:${actionType}`)} ${t('common:rule')}`}</Typography>
            </Box>
            <Fab variant="close" onClick={handleClose}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box-filters" sx={{ maxWidth: '35rem' }}>
            {message && <Box>{message}</Box>}
            {t('common:continue')}?
            <Box className="modal-filter-buttons">
              <Button size="large" variant="outlinedGrey" onClick={handleClose}>
                {t('common:cancel')}
              </Button>
              <Button size="large" variant="outlined" onClick={handleAction}>
                {t('common:Ok')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default ArchivedRules
