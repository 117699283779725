import { Masonry } from '@mui/lab'
import { Box, Paper, Typography } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import { RenderTooltip } from '../../../../../components/utilities/RenderTooltip'
import { useMemo } from 'react'

const analyticsEnum = {
  mrzFormat: 'MRZ format',
  mrzChecksum: 'MRZ checksum',
  lastName: 'Last name',
  firstName: 'First name',
  dob: 'Birth date',
  documentNumber: 'Document number',
  personalNumber: 'Personal number',
  expirationDate: 'Expiration date',
  issuingDate: 'Issuing date',
  dataIntegrity: 'Data integrity',
  specimenCheck: 'Specimen check',
  blackListCheck: 'Black list check',
  modelIdentification: 'Model identification',
  countryModelValidity: 'Country model validity',
  documentModelValidity: 'Document model validity',
  photocopyDetected: 'Photocopy detection',
  mrzVisualPlacement: 'MRZ visual elements',
  securityElements: 'Security elements',
  photoLocation: 'Photo location',
  mrzClassification: 'MRZ classification',
  documentLivenessCheck: 'Document liveness',
  tamperingDetected: 'Tampering detection',
  formatAnalysis: 'Format analysis',
  dataConsistency: 'Data consistency',
  ageVerification: 'Age verification',
  clientDataConsistency: 'Client data consistency',
  facialSimilarity: 'Facial similarity',
  previouslyEnrolledFace: 'Previously enrolled face',
  spoofedImageAnalysis: 'Spoofed image analysis',
  livenessCheck: 'Liveness check',
  faceDetection: 'Face detection',
  vpnDetected: 'VPN detection',
  controlNumber: 'Control number',
}

const excludedCheckAnlitics = ['rfidAnalysis', 'securityAndPatternAnalysis', 'extractedImages', 'extractedData']

const CheckAnalytics = ({ data, renaperData }) => {
  const { t } = useTranslation(['common', 'messages'])
  const controlNumber = data?.result?.breakdown?.extractedData?.documentDetails?.personalNumber
  const renaper = useMemo(() => {
    if (controlNumber && renaperData?.id_tramite_principal) {
      return {
        controlNumber: +controlNumber === +renaperData?.id_tramite_principal ? 'clear' : 'attention',
      }
    }
    return null
  }, [controlNumber, renaperData])

  const checkAnalytics = useMemo(() => {
    if (data?.result?.breakdown) {
      const resultData = {
        ...data.result.breakdown,
        ...(renaper && { renaper }),
      }
      const filteredData = Object.keys(resultData)
        .filter((key) => !excludedCheckAnlitics.includes(key))
        .reduce((obj, key) => {
          obj[key] = resultData[key]
          return obj
        }, {})

      const analytics = Object.entries(filteredData).reduce((acc, [key, value]) => {
        const newValue = { ...value }
        if (newValue.breakdown) {
          Object.assign(acc, newValue.breakdown)
          delete newValue.breakdown
        }
        acc[key] = newValue
        return acc
      }, {})

      return analytics
    }

    return null
  }, [data])

  if (!checkAnalytics) return null

  return (
    <Masonry columns={{ xs: 1, sm: 2, md: 2 }} spacing={2}>
      {Object.keys(checkAnalytics).map((item) => (
        <Paper elevation={0} variant="colorPrimary" className={styles.onboardingAnalysis} key={item}>
          <Box className={styles.onboardingTooltip}>
            <Typography variant="subtitle1">{t(`messages:kyc.check.analytics.${item}`)}</Typography>
            <RenderTooltip title={t(`messages:kyc.check.analytics.${item}.tooltip`)} />
          </Box>
          {typeof checkAnalytics[item] === 'object' &&
            Object.entries(checkAnalytics[item]).map(([key, value]) => (
              <Box className={styles.checkRender} key={key}>
                {(!value || value === 'not_processed') && (
                  <RemoveCircleRoundedIcon fontSize="small" className={styles.grey} />
                )}
                {value === 'clear' && <CheckCircleRoundedIcon fontSize="small" color="secondary" />}
                {value && value !== 'clear' && value !== 'not_processed' && (
                  <CancelRoundedIcon fontSize="small" className={styles.red} />
                )}
                <p>{analyticsEnum[key]}</p>
              </Box>
            ))}
          {typeof checkAnalytics[item] === 'number' && (
            <Typography variant="number">{checkAnalytics[item]}%</Typography>
          )}
        </Paper>
      ))}
    </Masonry>
  )
}

export default CheckAnalytics
