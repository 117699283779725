import { Box, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

const AlertActionChip = ({ action, showLabel = true }) => {
  const { t } = useTranslation(['common'])
  const label = t(`common:${action}`)

  const colorsByAction = {
    FLAG: {
      color: 'var(--alert-light)',
      backgroundColor: 'var(--alert-dark)',
    },
    BLOCK: {
      color: 'var(--error-dark)',
      backgroundColor: 'var(--error-light)',
    },
    SUSPEND: {
      color: 'var(--calm-dark)',
      backgroundColor: 'var(--calm-light)',
    },
  }

  const colors = colorsByAction[action] || {}

  return (
    <Box
      style={{
        backgroundColor: colors.backgroundColor,
      }}
      className="risk-level-icon"
    >
      <Tooltip title={label}>
        <p style={{ color: colors.color }}>{showLabel ? label : label[0]}</p>
      </Tooltip>
    </Box>
  )
}

export default AlertActionChip
