import { Box, Typography } from '@mui/material'
import CountUp from 'react-countup'

const Stat = ({ total, title }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center', gap: 1 }}>
      <Box>
        <Typography align="center" variant="h2">
          <CountUp end={total} />
        </Typography>
        <Typography align="center">{title}</Typography>
      </Box>
    </Box>
  )
}

export default Stat
