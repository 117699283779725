import React, { useState, useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat'

import '@n8n/chat/style.css'
import './FloatingChat.scss'
import { createChat } from '@n8n/chat'
import { useSelector } from 'react-redux'

const ChatButton = () => {
  const [open, setOpen] = useState(false)
  const languageState = useSelector((state) => state.language.lang)

  useEffect(() => {
    createChat({
      webhookUrl: 'https://gueno.app.n8n.cloud/webhook/0ab92077-f83f-4ac8-91b2-dd58b091d75e/chat',
      webhookConfig: {
        method: 'POST',
        headers: {},
      },
      target: '#n8n-chat',
      mode: 'window',
      chatInputKey: 'chatInput',
      chatSessionKey: 'sessionId',
      metadata: {},
      showWelcomeScreen: false,
      defaultLanguage: languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : 'en',
      initialMessages: ['Hi there! 👋', 'My name is Nathan. How can I assist you today?'],
      i18n: {
        en: {
          title: 'Hi there! 👋',
          subtitle: "Start a chat. We're here to help you 24/7.",
          footer: '',
          getStarted: 'New Conversation',
          inputPlaceholder: 'Type your question..',
        },
        'pt-br': {
          title: 'Olá! 👋',
          subtitle:
            'Inicie um chat. Estamos aqui para ajudar você 24/7. Você pode escrever no idioma que preferir, e Nathan se adaptará a você.',
          footer: '',
          getStarted: 'Nova conversa',
          inputPlaceholder: 'Digite sua pergunta..',
        },
        es: {
          title: 'Hola! 👋',
          subtitle:
            'Inicia un chat. Estamos disponibles las 24 horas, los 7 días de la semana. Puedes escribir en el idioma que prefieras, y Nathan se adaptará a ti.',
          footer: '',
          getStarted: 'Nueva conversación',
          inputPlaceholder: 'Escribe tu pregunta..',
        },
      },
    })
  }, [languageState])

  return (
    <Box>
      <IconButton
        onClick={() => setOpen(!open)}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        color="inherit"
      >
        {/* <ChatIcon /> */}
      </IconButton>
    </Box>
  )
}

export default ChatButton
