import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import demoData from './demoData'

const baseUrl = process.env.REACT_APP_CLIENT_SERVICES_API

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = getState().login
    headers.set('Authorization', `Bearer ${accessToken}`)
    return headers
  },
  fetchFn: async (...args) => {
    if (process.env.REACT_APP_IS_DEMO) {
      const url = new URL(args[0]?.url)
      const body = demoData[args[0]?.method][url?.pathname] || {}
      return new Response(JSON.stringify(body), {
        status: 200,
        statusText: 'OK',
        headers: {
          'content-type': 'application/json; charset=utf-8',
        },
      })
    }
    return fetch(...args)
  },
})

export const clientServicesApi = createApi({
  reducerPath: 'clientServicesApi',
  baseQuery,
  endpoints: (builder) => ({
    findConsumptions: builder.query({
      query: ({ tenant }) => {
        return {
          url: `/clients/consumptions`,
          params: { tenant },
        }
      },
    }),
    findConsumptionsByTenant: builder.query({
      query: ({ status, groupBy }) => {
        return {
          url: `/metrics/consumptions`,
          params: { status, groupBy },
        }
      },
    }),
  }),
})
