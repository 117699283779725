import './datatable.scss'
import { Box, Button, IconButton, Link, Modal, TablePagination, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import { toLocaleUTCDateString } from '../time/timeHelper'
import { Flags } from '../flag/flags'
import { useTranslation } from 'react-i18next'
import CustomColumnMenu from '../datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../datagrid/CustomLoadingOverlay'
import {
  useCreateBusinessUserEventMutation,
  useFindKytUsersQuery,
} from '../../../features/transactions/transactionSlice'
import toaster from '../../../toaster'
import EditStateUserRow from '../../../pages/kyt/components/EditStateUserRow'
import DataExportModal from '../dataExport/DataExportModal'
import {
  kytBusinessUserDataMapper,
  kytBusinessUserDataMapperForPDF,
} from '../dataExport/dataMappers/kytBusinessUserDataMapper'
import FiltersUsersBusinessForm from '../../../pages/kyt/components/FiltersUsersBusinessForm'
import dayjs from 'dayjs'
import { resetUsersFilters, updateUsersFilters } from '../../../features/users/userSlice'
import { formatDate } from '../../../pages/kyt/utils'
import useUserTableConfig from '../../hooks/useUserTableConfig'
import { getOrderedColumns, getVisibilityModel, updateListOrder } from './tableUtils/tableUtils'

const DatatableKytUserBusiness = () => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [page, setPage] = useState(0)

  const { emailClient, activeServices } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.user.filters)

  const [searchParams] = useSearchParams()
  const apiRef = useGridApiRef()
  const [canEdit, setCanEdit] = useState(false)
  const [isEditState, setIsEditState] = useState({})
  const [rowStates, setRowStates] = useState({})

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: `${t('common:user')} ID`,
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <Link component={ReactRouterLink} to={`/kyt/users/business/${row._id}`}>
              <Typography variant="number">{row.id}</Typography>
            </Link>
          )
        },
      },
      {
        field: 'legalName',
        minWidth: 260,
        headerName: t('common:legalName'),
        sortable: false,
      },

      {
        field: 'registrationCountry',
        headerName: t('common:registrationCountry'),
        minWidth: 150,
        sortable: false,
        renderCell: (params) => {
          return Flags(params.row.registrationCountry)
        },
      },
      {
        field: 'expectedTransactionAmountPerMonth',
        headerName: t('common:expectedTransaction'),
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => {
          return <Box className="table-body-cell">{row.expectedTransactionAmountPerMonth?.toLocaleString()}</Box>
        },
      },
      {
        field: 'expectedTurnoverAmountPerMonth',
        headerName: t('common:expectedTurnover'),
        minWidth: 180,
        sortable: false,
        renderCell: (params) => {
          return <Box className="table-body-cell">{params.row.expectedTurnoverAmountPerMonth?.toLocaleString()}</Box>
        },
      },
      {
        field: 'createdTimestamp',
        headerName: t('common:creationTime'),
        minWidth: 200,
        sortable: false,
        renderCell: (params) => {
          return <Typography variant="number">{toLocaleUTCDateString(params.row.createdTimestamp)}</Typography>
        },
      },
      {
        field: 'details',
        minWidth: 250,
        headerName: t('common:details'),
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => {
          const isLoading = !!isCreatingUserEvent && createUserEventArgs?.id === row.id

          return (
            <EditStateUserRow
              isActive={isEditState[row.id] || false}
              canEdit={canEdit}
              isLoading={isLoading}
              currentValue={row.userStateDetails?.state}
              newValue={rowStates[row.id]}
              onChangeActive={(active) =>
                setIsEditState((prevState) => ({
                  ...prevState,
                  [row.id]: active,
                }))
              }
              onChangeRowState={(rowState) =>
                setRowStates((prevState) => ({
                  ...prevState,
                  [row.id]: rowState,
                }))
              }
              onSubmit={() => handleCreateUserEvent(row.id, rowStates[row.id])}
            />
          )
        },
      },
    ],
    [],
  )

  const {
    config,
    saveConfig,
    loading: configLoading,
  } = useUserTableConfig({
    tableKey: 'kytBusiness',
    defaultConfig: {
      rowsPerPage: 25,
      pinnedColumns: { left: [], right: [] },
      columnOrder: columns.map((c) => c.field),
    },
  })

  const [createUserEvent, { isLoading: isCreatingUserEvent, originalArgs: createUserEventArgs }] =
    useCreateBusinessUserEventMutation()

  const { data, isLoading, isFetching, refetch } = useFindKytUsersQuery({
    ...queryState,
    type: 'businessUsers',
    emailClient,
    page,
    limit: config.rowsPerPage,
    offset: config.rowsPerPage * page,
  })

  const defaultFromDate = queryState.fromDate ? dayjs(queryState.fromDate) : null
  const defaultToDate = queryState.toDate ? dayjs(queryState.toDate) : null

  const { users, pagination } = useMemo(() => {
    if (data?.data) {
      return {
        users: data.data,
        pagination: data.pagination,
      }
    }
    return {
      users: [],
      pagination: {},
    }
  }, [data])

  const { uniqueKeys, uniqueValues } = useMemo(
    () => ({ uniqueKeys: data?.unique?.uniqueKeys || [], uniqueValues: data?.unique?.uniqueValues || [] }),
    [data?.unique],
  )

  useEffect(() => {
    if (Array.isArray(activeServices)) {
      if (searchParams.get('type') === 'businessUsers' && activeServices.indexOf('kytcreateBusinessUserEvent') > -1) {
        setCanEdit(true)
      }
    }
  }, [activeServices, searchParams])

  const handleCreateUserEvent = async (id, state) => {
    try {
      await createUserEvent({ id, state }).unwrap()

      setRowStates((prevRowStates) => ({
        ...prevRowStates,
        [id]: state,
      }))

      toaster.success(t('messages:creationSuccess'))
    } catch (err) {
      console.error(err)
      toaster.error(t('messages:creationError'))
    } finally {
      setIsEditState((prevState) => ({
        ...prevState,
        [id]: false,
      }))
    }
  }

  const handleDispatch = (values) => {
    const { fromDate, fromTime, toDate, toTime, ...filters } = values
    return dispatch(
      updateUsersFilters({
        viewMode: true,
        page,
        limit: config.rowsPerPage,
        offset: config.rowsPerPage * page,
        fromDate: formatDate(fromDate, fromTime),
        toDate: formatDate(toDate, toTime),
        ...filters,
      }),
    )
  }

  const handleResetButton = () => {
    setPage(0)
    dispatch(resetUsersFilters())
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    saveConfig({ rowsPerPage: newRowsPerPage })

    setPage(0)
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const orderedColumns = useMemo(
    () => getOrderedColumns(config.columnOrder, columns),
    [JSON.stringify(config.columnOrder), columns],
  )

  const handleColumnOrderChange = (params) => {
    const updatedOrder = updateListOrder(config.columnOrder, params)
    if (JSON.stringify(config.columnOrder) !== JSON.stringify(updatedOrder)) {
      saveConfig({ columnOrder: updatedOrder })
    }
  }

  const visibleColumnsModel = useMemo(
    () => getVisibilityModel(config.visibleColumns, columns),
    [config.visibleColumns, columns],
  )

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Box className="filter-bar">
        <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setFiltersOpen(true)}>
          {t('common:filters')}
        </Button>
        <TablePagination
          labelRowsPerPage={[]}
          component="div"
          count={pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={config.rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {activeServices.includes('reportsitem') && (
            <Button
              variant="outlined"
              disabled={!activeServices.includes('reportsitem')}
              onClick={() => setShowDownloadModal(true)}
              endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
            >
              <Typography variant="title2">{t('common:exportReport')}</Typography>
            </Button>
          )}
          <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
            <RefreshIcon color="action" />
          </IconButton>
        </Box>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={users || []}
        columns={orderedColumns}
        apiRef={apiRef}
        slots={{
          columnMenu: CustomColumnMenu,
          loadingOverlay: CustomLoadingOverlay,
        }}
        hideFooter={true}
        autoHeight
        loading={isFetching || isLoading || configLoading}
        columnOrder={config.columnOrder}
        onColumnOrderChange={handleColumnOrderChange}
        pinnedColumns={config.pinnedColumns}
        onPinnedColumnsChange={(newPinnedColumns) => saveConfig({ pinnedColumns: newPinnedColumns })}
        columnVisibilityModel={visibleColumnsModel}
        onColumnVisibilityModelChange={(newModel) => saveConfig({ visibleColumns: newModel })}
      />
      <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
        <div>
          <FiltersUsersBusinessForm
            defaultValues={{
              userId: queryState.userId,
              fromDate: defaultFromDate || null,
              fromTime: defaultFromDate ? defaultFromDate.format('HH:mm') : '00:00',
              toDate: defaultToDate || null,
              toTime: defaultToDate ? defaultToDate.format('HH:mm') : '23:59',
              legalName: queryState.legalName,
              countryOfRegistration: queryState.countryOfRegistration,
              userKycStatus: queryState.userKycStatus,
              selectedUserState: queryState.selectedUserState,
              userKey: queryState.userKey,
              userValue: queryState.userValue,
            }}
            setModalOpen={setFiltersOpen}
            handleResetButton={handleResetButton}
            handleDispatch={handleDispatch}
            setPage={setPage}
            uniqueKeys={uniqueKeys}
            uniqueValues={uniqueValues}
          />
        </div>
      </Modal>
      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={users}
            fileName="businessUsers"
            dataMapper={kytBusinessUserDataMapper}
            dataMapperForPDF={kytBusinessUserDataMapperForPDF}
            typeDataExport="businessUsers"
          />
        </div>
      </Modal>
    </Box>
  )
}

export default DatatableKytUserBusiness
