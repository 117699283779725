import { useCallback } from 'react'
import 'react-querybuilder/dist/query-builder.css'
import { QueryBuilder } from 'react-querybuilder'
import { Controller } from 'react-hook-form'

import { customOperators, riskMatrixAttributes } from '../constantsV2'
import CustomRule from './CustomRule'
import CustomGroup from './CustomGroup'

const RiskMatrixParameterV2 = ({ control, country, target }) => {
  const CustomRuleWithCountry = useCallback((props) => <CustomRule {...props} country={country} />, [country])
  const CustomGroupWithCountry = useCallback((props) => <CustomGroup {...props} country={country} />, [country])

  return (
    <Controller
      name="params"
      control={control}
      render={({ field }) => {
        return (
          <QueryBuilder
            fields={riskMatrixAttributes[target][country]}
            query={field.value}
            onQueryChange={field.onChange}
            operators={customOperators}
            controlElements={{
              rule: CustomRuleWithCountry,
              ruleGroup: CustomGroupWithCountry,
            }}
          />
        )
      }}
    />
  )
}

export default RiskMatrixParameterV2
