/* eslint-disable react-hooks/exhaustive-deps */
import './datatable.scss'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link as ReactRouterLink } from 'react-router-dom'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Button, IconButton, Link, TablePagination, Typography } from '@mui/material'
import { TableChip } from '../../utilities/TableChip'
import { TableIcons } from '../../utilities/TableIcons'
import { Flags } from '../flag/flags'
import CustomColumnMenu from '../datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../datagrid/CustomLoadingOverlay'
import { toCustomUTCDateString } from '../time/timeHelper'
import { selectUserMaster } from '../../../features/kyb/kybCountrySlice'
import { useMemo } from 'react'

const Datatable = ({ transactionsPage, page, setPage, setModalOpen, isLoading, refetch, config, saveConfig }) => {
  const { t } = useTranslation(['common'])
  const { emailClient } = useSelector((state) => state.login)
  const userMaster = useSelector((state) => selectUserMaster(state, emailClient))
  const languageState = useSelector((state) => state.language.lang)

  const apiRef = useGridApiRef()

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Id',
        minWidth: 200,
        sortable: false,
        renderCell: (params) => {
          return (
            <Link
              component={ReactRouterLink}
              to={`/transactions/${params.id}`}
              title={params.id}
              style={{ wordBreak: 'break-word' }}
            >
              <Typography variant="number">{params.id}</Typography>
            </Link>
          )
        },
      },
      {
        field: 'transactionType',
        headerName: t('common:type'),
        minWidth: 120,
        renderCell: (params) => {
          return params.row?.transactionType ? <TableIcons type={params.row?.transactionType} /> : '---'
        },
        sortable: false,
      },
      {
        field: 'transactionState',
        headerName: t('common:state'),
        minWidth: 150,
        renderCell: (params) => {
          return params.row.transactionState ? <TableIcons type={params.row.transactionState} /> : '---'
        },
        sortable: false,
      },
      {
        field: 'createdTimestamp',
        headerName: t('common:date'),
        minWidth: 200,
        renderCell: (params) => {
          return toCustomUTCDateString(params.row?.createdTimestamp, userMaster?.timezone)
        },
      },
      {
        field: 'alert',
        headerName: t('common:alert'),
        minWidth: 120,
        renderCell: (params) => {
          return <Box>{params.row.alert ? <TableChip action={params.row.alert} /> : '---'}</Box>
        },
        sortable: false,
      },
      {
        field: 'hitRules',
        minWidth: 100,
        headerName: t('common:hitRules'),
        renderCell: (params) => {
          return (
            <Box className="cellOverflow">
              {params.row.hitRules.length
                ? params.row.hitRules.map((item) => `${item.ruleId}(${item.ruleAction[0]})`).join(', ')
                : '---'}
            </Box>
          )
        },
        sortable: false,
      },
      {
        field: 'originUserId',
        minWidth: 120,
        headerName: t('common:originUserId'),
        sortable: false,
      },
      {
        field: 'originMethod',
        minWidth: 200,
        headerName: t('common:originMethod'),
        renderCell: (params) => {
          return params.row.originMethod ? (
            <TableChip action={params.row.originMethod.toUpperCase()} noIcon={true} />
          ) : (
            '---'
          )
        },
        sortable: false,
      },
      {
        field: 'originAmount',
        headerName: t('common:originAmount'),
        renderCell: (params) => {
          return (
            <Typography variant="number">
              {new Intl.NumberFormat(languageState, {
                minimumFractionDigits: 2,
                useGrouping: true,
              }).format(params.row.originAmount)}
            </Typography>
          )
        },
        sortable: false,
      },
      {
        field: 'originCurrency',
        headerName: t('common:originCurrency'),
        sortable: false,
      },
      {
        field: 'originCountry',
        headerName: t('common:originCountry'),
        renderCell: (params) => {
          return <Box>{Flags(params.row.originCountry, false)}</Box>
        },
        sortable: false,
      },
      {
        field: 'destinationUserId',
        minWidth: 120,
        headerName: t('common:destinationUserId'),
        sortable: false,
      },
      {
        field: 'destinationMethod',
        minWidth: 210,
        headerName: t('common:destinationMethod'),
        renderCell: (params) => {
          return (
            <Box>
              {params.row.destinationMethod ? <TableChip action={params.row.destinationMethod.toUpperCase()} /> : '---'}
            </Box>
          )
        },
        sortable: false,
      },
      {
        field: 'destinationAmount',
        headerName: t('common:destinationAmount'),
        renderCell: (params) => {
          return (
            <Typography variant="number">
              {new Intl.NumberFormat(languageState, {
                minimumFractionDigits: 2,
                useGrouping: true,
              }).format(params.row.destinationAmount)}
            </Typography>
          )
        },
        sortable: false,
      },
      {
        field: 'destinationCurrency',
        headerName: t('common:destinationCurrency'),
        sortable: false,
      },
      {
        field: 'destinationCountry',
        headerName: t('common:destinationCountry'),
        renderCell: (params) => {
          return <Box>{Flags(params.row.destinationCountry, false)}</Box>
        },
        sortable: false,
      },
    ],
    [],
  )

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const orderedColumns = useMemo(() => {
    if (!Array.isArray(config.columnOrder) || config.columnOrder.length === 0) {
      return columns
    }
    return config.columnOrder.map((field) => columns.find((col) => col.field === field)).filter(Boolean)
  }, [JSON.stringify(config.columnOrder), columns])

  const handleColumnOrderChange = (params) => {
    const { targetIndex, oldIndex } = params

    if (!Array.isArray(config.columnOrder)) {
      console.error('columnOrder is not an array:', config.columnOrder)
      return
    }

    const updatedOrder = [...config.columnOrder]
    const movedColumnField = updatedOrder.splice(oldIndex, 1)[0]
    updatedOrder.splice(targetIndex, 0, movedColumnField)

    if (JSON.stringify(config.columnOrder) !== JSON.stringify(updatedOrder)) {
      saveConfig({ columnOrder: updatedOrder })
    }
  }

  const visibleColumnsModel = useMemo(() => {
    if (!config.visibleColumns) {
      return columns.reduce((acc, col) => {
        acc[col.field] = true
        return acc
      }, {})
    }
    return config.visibleColumns
  }, [config.visibleColumns, columns])

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Box className="filter-bar">
        <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
          {t('common:filters')}
        </Button>
        <TablePagination
          labelRowsPerPage={[]}
          component="div"
          count={transactionsPage?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={config.rowsPerPage}
          onRowsPerPageChange={(event) => {
            const newRowsPerPage = parseInt(event.target.value, 10)
            saveConfig({ rowsPerPage: newRowsPerPage })
          }}
          showFirstButton
          showLastButton
        />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Button
            variant="label"
            endIcon={<MenuOutlinedIcon />}
            onClick={() => apiRef.current.showPreferences('columns')}
          >
            {t('common:showHideColumns')}
          </Button>
          <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
            <RefreshIcon color="action" />
          </IconButton>
        </Box>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={Array.isArray(transactionsPage?.data) ? transactionsPage?.data : []}
        columns={orderedColumns}
        apiRef={apiRef}
        slots={{
          columnMenu: CustomColumnMenu,
          loadingOverlay: CustomLoadingOverlay,
        }}
        hideFooter={true}
        autoHeight
        sortingMode="server"
        sortModel={config.sortModel}
        onSortModelChange={(newSortModel) => saveConfig({ sortModel: newSortModel })}
        columnOrder={config.columnOrder}
        onColumnOrderChange={handleColumnOrderChange}
        pinnedColumns={config.pinnedColumns}
        onPinnedColumnsChange={(newPinnedColumns) => saveConfig({ pinnedColumns: newPinnedColumns })}
        columnVisibilityModel={visibleColumnsModel}
        onColumnVisibilityModelChange={(newModel) => saveConfig({ visibleColumns: newModel })}
        loading={isLoading}
      />
    </Box>
  )
}

export default Datatable
