import './Tabs.scss'
import { Box, Button, Checkbox, Divider, FormControlLabel, TableContainer, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { DataGridPro } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { useEffect, useMemo, useState } from 'react'
import CustomColumnMenu from '../../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../../components/common/datagrid/CustomLoadingOverlay'
import Spinner from '../../../../components/common/spinner/spinner'
import { buildTimeStringFromTimestamp } from '../../../../components/common/time/timeHelper'
import { useGetCaseChecklistsByCaseIdQuery } from '../../../../features/cases/caseSlice'
import { useUpdateChecklistInstanceMutation } from '../../../../services/guenoApi'
import toaster from '../../../../toaster'

const TabChecklists = ({ caseId, hasPermissions }) => {
  const location = useLocation()
  const { t } = useTranslation(['common'])
  const [isEditing, setIsEditing] = useState({})
  const [updatedChecklists, setUpdatedChecklists] = useState([])
  const [updateChecklistInstance, { isLoading: isUpdatingChecklists }] = useUpdateChecklistInstanceMutation()

  const {
    data: checklistsData,
    isLoading: isLoadingChecklists,
    refetch,
  } = useGetCaseChecklistsByCaseIdQuery({ id: caseId })
  const checklists = useMemo(() => checklistsData?.data, [checklistsData?.data])

  useEffect(() => {
    if (checklists) {
      setUpdatedChecklists(checklists)
    }
  }, [checklists])

  const handleUpdateChecklist = async (checkId, actions) => {
    try {
      const body = { actions }
      await updateChecklistInstance({ checkId, body }).unwrap()
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      console.error('[UPDATE CHECKLIST INSTANCE ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:updateError'))
    } finally {
      setIsEditing(false)
      refetch()
    }
  }

  const columns = [
    {
      field: '_id',
      headerName: 'ID',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <Box>{params.row._id}</Box>
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return <Box>{params.row.title ? params.row.title : '---'}</Box>
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 3,
      minWidth: 140,
      renderCell: (params) => {
        return <Box>{params.row.description ? params.row.description : '---'}</Box>
      },
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      hide: location?.pathname?.includes('case-management') && !hasPermissions,
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return <Box>{params.row.actions ? params.row.actions.length : '---'}</Box>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return buildTimeStringFromTimestamp(params.row.createdAt)
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return buildTimeStringFromTimestamp(params.row.updatedAt)
      },
    },
  ]

  const checkHandler = (completed, checklistId, actionIndex) => {
    setUpdatedChecklists((prevChecklists) =>
      prevChecklists.map((checklist) =>
        checklist._id === checklistId
          ? {
              ...checklist,
              actions: checklist.actions.map((action, index) =>
                index === actionIndex ? { ...action, completed } : action,
              ),
            }
          : checklist,
      ),
    )
  }

  const getDetailPanelContent = ({ row }) => {
    const checklist = updatedChecklists.find((item) => item._id === row._id) || row
    return (
      <Box key={row._id} className="tabs-expandable-container">
        <Box className="tabs-expandable-module">
          <Box className="tabs-expandable-text">
            <Typography variant="title2">{t('common:checklist')} ID:</Typography>

            <Typography variant="number">{row._id}</Typography>
          </Box>

          <Divider orientation="vertical" variant="middle" flexItem />

          <Box className="tabs-expandable-module">
            <Button
              disabled={!hasPermissions}
              onClick={() => {
                setIsEditing((prev) => ({
                  ...prev,
                  [row._id]: !prev[row._id],
                }))
              }}
              variant={!isEditing[row._id] ? 'outlinedButtonSuccess' : 'outlinedButtonError'}
              className="icon-fab"
            >
              {!isEditing[row._id] ? <EditIcon /> : <CloseIcon />}
            </Button>

            {isEditing[row._id] && (
              <Button
                disabled={!hasPermissions}
                onClick={() => handleUpdateChecklist(checklist?._id, checklist?.actions)}
                variant="outlinedButtonSuccess"
                className="icon-fab"
              >
                <CheckIcon />
              </Button>
            )}
          </Box>
        </Box>

        <Divider />
        <Box className="tabs-expandable-module">
          <Box className="tabs-expandable-text">
            <Typography variant="title2">{t('common:title')}:</Typography>
            <Typography variant="h5">{row?.title}</Typography>
          </Box>
          <Box className="tabs-expandable-text">
            <Typography variant="title2">{t('common:description')}:</Typography>
            <Typography variant="h5">{row?.description}</Typography>
          </Box>
        </Box>

        <Divider />

        <Box className="tabs-checkbox">
          <Typography variant="title2">{t('common:actions')}:</Typography>
          <Box className="tabs-checkbox-item">
            {checklist?.actions?.map((item, index) => (
              <Box className="tabs-checkbox-box" key={index}>
                <FormControlLabel
                  disabled={!isEditing[row._id]}
                  control={<Checkbox checked={item.completed} />}
                  onChange={() => checkHandler(!item.completed, row._id, index)}
                />

                <Typography variant="subtitle3">{item.action}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {isLoadingChecklists && <Spinner />}
      <Box className={`${isLoadingChecklists && 'spinner-transition'} datatable`}>
        <TableContainer>
          <Box className="grid-table-container">
            <DataGridPro
              getRowHeight={() => 'auto'}
              getRowId={(row) => row._id || row.id}
              rows={(Array.isArray(checklists) && checklists.length && checklists) || []}
              columns={columns}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={() => 'auto'}
              slots={{
                columnMenu: CustomColumnMenu,
                loadingOverlay: CustomLoadingOverlay,
              }}
              hideFooter={true}
              autoHeight
              sortingMode="server"
              loading={isLoadingChecklists || isUpdatingChecklists}
            />
          </Box>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default TabChecklists
