import { createSlice } from '@reduxjs/toolkit'
import { cleanParams } from '../../services/builders'
import guenoApi from '../../services/guenoApi'

export const defaultFilters = {
  viewMode: true,
  page: 0,
  limit: 10,
  fromDate: null,
  toDate: '',
  offset: 0,
  emailClient: '',
  email: '',
  status: '',
  roles: [],
}

export const userAdminSlice = createSlice({
  name: 'userAdmin',
  initialState: {
    users: [],
    filters: {
      ...defaultFilters,
    },
  },
  reducers: {
    updateUsersAdmin: (state, action) => {
      state.users = action.payload
    },
    performLogoutUserAdmin: (state) => {
      state.users = []
    },
    updateUsersAdminFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

const endpointsUsersAdmin = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findUsersAdmin: builder.query({
      query: ({ emailClient, email, status, roles, viewMode, limit, page, offset }) => {
        const filteredParams = cleanParams({
          emailClient,
          email,
          status,
          roles,
          viewMode,
          limit,
          page,
          offset,
        })
        return {
          url: `/user/all`,
          params: filteredParams,
        }
      },
    }),
    updatePasswordUser: builder.mutation({
      query: ({ body }) => ({
        url: `/user/resetPassword`,
        method: 'PATCH',
        body,
      }),
    }),
    createUser: builder.mutation({
      query: ({ body }) => ({
        url: `/user`,
        method: 'POST',
        body,
      }),
    }),
    updateUser: builder.mutation({
      query: ({ body, email }) => ({
        url: `/user/${email}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteUser: builder.mutation({
      query: ({ email }) => ({
        url: `/user/${email}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const { updateUsersAdmin, performLogoutUserAdmin, updateUsersAdminFilters } = userAdminSlice.actions
export const {
  useFindUsersAdminQuery,
  useUpdatePasswordUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = endpointsUsersAdmin
export default userAdminSlice.reducer
