import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUpdateKycCheckMutation, useUpdateKycPersonMutation } from '../../../../../features/kyc/kycSlice'
import ModalTitle from '../../../../../components/common/Modal/ModalTitle'
import { InputLabel, MenuItem, Select } from '@mui/material'
import { TextInputForm } from '../../../../../components/common/form'

const EditFieldModal = ({ setModalOpen, field, value, options, onSubmit, validation = '' }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [_, { isLoading: isLoadingPerson }] = useUpdateKycPersonMutation({ fixedCacheKey: 'kyc-person-update' })
  const [__, { isLoading: isLoadingCheck }] = useUpdateKycCheckMutation({ fixedCacheKey: 'kyc-check-update' })

  const validations = {
    email: yup.string().email(),
    text: yup.string(),
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        value: validations[validation] || validations.text,
      }),
    ),
    defaultValues: { value: value },
  })

  const handleFormSubmit = ({ value }) => {
    onSubmit({ [field]: value })
  }

  const handleModalClose = () => {
    reset({ value })
    setModalOpen(false)
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box className="modal">
        <ModalTitle setModalOpen={handleModalClose} title={`${t('common:edit')} ${t(`common:${field}`)}`} />
        <Box className="modal-box">
          <InputLabel>{t(`common:${field}`)}:</InputLabel>
          {options && options.length > 0 ? (
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <Select {...field} value={field.value || ''} onChange={(e) => field.onChange(e.target.value)}>
                  {options.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        {item}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          ) : (
            <TextInputForm name="value" control={control} />
          )}
        </Box>
        <Box className="modal-button">
          <LoadingButton
            size="small"
            color="secondary"
            variant="contained"
            disabled={!isDirty || !isValid}
            type="submit"
            loading={isLoadingPerson || isLoadingCheck}
          >
            {t('common:save')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  )
}

export default EditFieldModal
