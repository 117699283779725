import './datatable.scss'
import { Stack, TableContainer, TablePagination, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { toCustomUTCDateString } from '../time/timeHelper'
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { useFindKytUserActionsQuery } from '../../../services/guenoApi'
import IconLib from '../../../styles/icons/IconLib'
import { selectUserMaster } from '../../../features/kyb/kybCountrySlice'
import { useSelector } from 'react-redux'

const BROWSER_ICON_SUPPORT = ['chrome', 'firefox']

const extractBrowserInfo = (device) => {
  const deviceLowerCase = device.toLowerCase()

  const parts = deviceLowerCase.split(' ')

  const browser = parts[0]

  const version = parts[1]

  return { browser, version }
}

const DatatableUserActions = ({ userId }) => {
  const { emailClient } = useSelector((state) => state.login)
  const userMaster = useSelector((state) => selectUserMaster(state, emailClient))
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { t } = useTranslation(['common'])

  const { isFetching, isLoading, refetch, data } = useFindKytUserActionsQuery({
    id: userId,
    limit: rowsPerPage,
    page,
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const columns = [
    {
      field: 'action',
      headerName: t('common:action'),
      flex: 0.8,
      minWidth: 100,
      renderCell: ({ row }) => {
        return <Typography variant="number">{row.action}</Typography>
      },
    },
    {
      field: 'metadata.ip',
      headerName: t('common:ip'),
      flex: 0.7,
      minWidth: 100,
      renderCell: ({ row }) => {
        return <Typography variant="number">{row.metadata?.ip || '---'}</Typography>
      },
    },
    {
      field: 'metadata.contactUserId',
      headerName: t('common:contactUserId'),
      flex: 0.7,
      minWidth: 100,
      renderCell: ({ row }) => {
        return <Typography variant="number">{row.metadata?.contactUserId || '---'}</Typography>
      },
    },
    {
      field: 'time',
      headerName: t('common:date'),
      flex: 0.8,
      minWidth: 100,
      valueGetter: ({ row }) => {
        return toCustomUTCDateString(row.time, userMaster?.timezone)
      },
    },
    {
      field: 'metadata.device',
      headerName: t('common:device'),
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        const device = row.metadata?.device
        const incognito = row.metadata?.incognito

        if (!device) {
          if (!!incognito) return <IconLib svg="incognito" classes="svg-icon-behavior" />
          else return <Typography variant="number">---</Typography>
        }

        const { browser, version } = extractBrowserInfo(device)

        return (
          <Typography variant="number">
            <Stack direction="row" alignItems="center" spacing={1}>
              {BROWSER_ICON_SUPPORT.includes(browser) ? (
                <IconLib svg={browser} classes="svg-icon-behavior" />
              ) : (
                <Typography variant="number">{browser}</Typography>
              )}
              {version && <Typography variant="number">{version}</Typography>}
              {!!incognito && <IconLib svg="incognito" classes="svg-icon-behavior" />}
            </Stack>
          </Typography>
        )
      },
    },
    {
      field: 'metadata.vpn',
      headerName: t('common:vpn'),
      flex: 0.5,
      minWidth: 80,
      renderCell: ({ row }) => {
        const vpn = row.metadata?.vpn

        if (vpn === undefined) {
          return <Typography variant="number">---</Typography>
        }

        return <Typography variant="number">{vpn ? t('common:yes') : t('common:no')}</Typography>
      },
    },
    {
      field: 'metadata.location',
      headerName: t('common:location'),
      flex: 0.9,
      minWidth: 150,
      renderCell: (params) => {
        const location = params.row.metadata?.location
        const city = location?.city || '---'
        const country = location?.country || '---'
        return (
          <Typography variant="number">
            {city !== '---' && country !== '---' ? `${city}, ${country}` : city !== '---' ? city : country}
          </Typography>
        )
      },
    },
  ]

  const userActions = useMemo(() => {
    if (data?.data) return data?.data?.items
  }, [data])

  const pagination = useMemo(() => {
    if (data?.data) return data?.data?.pagination
  }, [data])

  return (
    <TableContainer>
      <DataGrid
        getRowId={(row) => row._id}
        rows={userActions || []}
        columns={columns}
        hideFooter={true}
        autoHeight={true}
        loading={isFetching || isLoading}
      />
      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={pagination?.totalElements || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default DatatableUserActions
