/* eslint-disable react-hooks/exhaustive-deps */
import './rule.scss'

import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useNavigate, useParams } from 'react-router-dom'
import '../home/home.scss'
import MyRules from '../../components/kyt/rules/myRules/MyRules'
import RequestRule from '../../components/kyt/rules/requestRule/RequestRule'
import { useRules } from '../pages-hooks/useRules'
import { useInstanceRules } from '../pages-hooks/useIntanceRules'
import { useDispatch, useSelector } from 'react-redux'
import { updateInstanceRules } from '../../features/rules/rulesSlice'
import RulesLibrary from '../../components/kyt/rules/rulesLibrary/RulesLibrary'
import { Button, Chip, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Spinner from '../../components/common/spinner/spinner'
import ArchivedRules from '../../components/kyt/rules/archivedRules/ArchivedRules'

const Rule = () => {
  const { activeServices } = useSelector((state) => state.login)
  const { actualTab } = useParams()
  const [value, setValue] = useState('library')
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])
  const [isLoadingInstances, setIsLoadingInstances] = useState(true)
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true)
  const [rules, setRules] = useState([])
  const [categories, setCategories] = useState([])
  const [instanceRules, setInstanceRules] = useState([])

  useRules(isLoadingTemplates, setIsLoadingTemplates, setRules, categories)
  useInstanceRules(
    isLoadingInstances,
    setIsLoadingInstances,
    instanceRules,
    setInstanceRules,
    actualTab === 'archived-rules',
    categories,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      !actualTab ||
      (actualTab !== 'library' &&
        actualTab !== 'my-rules' &&
        actualTab !== 'request-new' &&
        actualTab !== 'archived-rules')
    ) {
      navigate('/rules/library')
      setValue('library')
    } else {
      navigate(`/rules/${actualTab}`)
      setValue(actualTab)
    }
  }, [])

  const handleChange = (event, newValue) => {
    navigate(`/rules/${newValue}`)
    setValue(newValue)
  }

  useEffect(() => {
    dispatch(updateInstanceRules([...instanceRules]))
  }, [instanceRules])

  const verifyCreation = activeServices?.some(
    (path) => path.includes('retrieveClientRules') || path.includes('retrieveClientRuleById'),
  )
  const verifyMyRules = activeServices?.some((path) => path.includes('retrieveClientInstanceRules'))
  const verifyRequestNew = activeServices?.some((path) => path.includes('sendSlackMessage'))
  const verifyEdition = activeServices?.some((path) => path.includes('updateClientInstanceRule'))
  const verifyDeletion = activeServices?.some((path) => path.includes('deleteClientInstanceRule'))

  useEffect(() => {
    if (actualTab === 'my-rules' || actualTab === 'archived-rules') {
      setIsLoadingInstances(true)
    }
    if (actualTab && actualTab === 'library' && !verifyCreation) {
      navigate('/rules/my-rules')
      setValue('my-rules')
      setIsLoadingInstances(true)
    } else if (actualTab && (actualTab === 'my-rules' || actualTab === 'archived-rules') && !verifyMyRules) {
      navigate('/rules/request-new')
      setValue('request-new')
    } else if (actualTab && actualTab === 'request-new' && !verifyRequestNew) {
      navigate('/')
      setValue('')
    }
  }, [actualTab])

  return (
    <Box>
      <Box className={`${(isLoadingInstances || isLoadingTemplates) && 'spinner-transition'} filter`}>
        {(isLoadingInstances || isLoadingTemplates) && <Spinner noTransform />}
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:rules')}</Typography>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <TabContext value={value}>
          <Box className="component-title">
            <TabList
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
              sx={{ marginBottom: '1rem' }}
              onChange={handleChange}
            >
              {verifyMyRules && (
                <Tab
                  label={
                    <Chip label={t('common:myRules')} variant={value === 'my-rules' ? 'sliderSelected' : 'slider'} />
                  }
                  value="my-rules"
                />
              )}
              {verifyCreation && (
                <Tab
                  label={
                    <Chip
                      label={t('common:rulesLibrary')}
                      variant={value === 'library' ? 'sliderSelected' : 'slider'}
                    />
                  }
                  value="library"
                />
              )}
              {verifyMyRules && (
                <Tab
                  label={
                    <Chip
                      label={t('common:archivedRules')}
                      variant={value === 'archived-rules' ? 'sliderSelected' : 'slider'}
                    />
                  }
                  value="archived-rules"
                />
              )}
            </TabList>
            {verifyRequestNew && (
              <Button onClick={() => setValue('request-new')} variant="outlinedGrey">
                {t('common:requestRule')}
              </Button>
            )}
          </Box>

          <Paper elevation={0}>
            {verifyCreation && (
              <TabPanel value="library">
                <RulesLibrary
                  isLoading={isLoadingTemplates}
                  setIsLoading={setIsLoadingTemplates}
                  rules={rules}
                  instanceRules={instanceRules}
                  setInstanceRules={setInstanceRules}
                  categories={categories}
                  setCategories={setCategories}
                />
              </TabPanel>
            )}
            {verifyMyRules && (
              <TabPanel value="my-rules">
                <MyRules
                  verifyEdition={verifyEdition}
                  verifyDeletion={verifyDeletion}
                  isLoading={isLoadingInstances}
                  rules={rules}
                  instanceRules={instanceRules}
                  setIsLoading={setIsLoadingInstances}
                  setInstanceRules={setInstanceRules}
                  categories={categories}
                  setCategories={setCategories}
                />
              </TabPanel>
            )}
            {verifyMyRules && (
              <TabPanel value="archived-rules">
                <ArchivedRules
                  verifyEdition={verifyEdition}
                  verifyDeletion={verifyDeletion}
                  isLoading={isLoadingInstances}
                  rules={rules}
                  instanceRules={instanceRules}
                  setIsLoading={setIsLoadingInstances}
                  setInstanceRules={setInstanceRules}
                  categories={categories}
                  setCategories={setCategories}
                />
              </TabPanel>
            )}
            {verifyRequestNew && (
              <TabPanel value="request-new">
                <RequestRule aux={'P1'} />
              </TabPanel>
            )}
          </Paper>
        </TabContext>
      </Box>
    </Box>
  )
}

export default Rule
