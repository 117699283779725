import React from 'react'
import { useState } from 'react'
import { Paper, TextField, Button, Box, InputLabel } from '@mui/material'
import { Alert } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import jwt_decode from 'jwt-decode'
import GuenoLogo from '../../assets/logos/logo-gueno-light2.png'
import GuenoLogoDark from '../../assets/logos/logo-gueno2.png'
import { useDispatch, useSelector } from 'react-redux'
import { performSetServices } from '../../features/auth/loginSlice'
import './login.scss'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

const Login = ({ handleUserLogin }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])
  const { theme } = useSelector((state) => state.theme)

  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')

  const [isUserEmailOK, setIsUserEmailOK] = useState(false)
  const [isUserPasswordOK, setIsUserPasswordOK] = useState(false)

  const [upError, setUPError] = useState(false)
  const [showPassword, sestShowPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  let isSignUpBtnOK = isUserEmailOK && isUserPasswordOK

  const validateUserEmail = (userEmail) => {
    const emailPattern = /^[\w+.-]+@([\w-]+\.)+[\w-]{2,4}$/
    return emailPattern.test(userEmail)
  }

  const validateUserPassword = (userPassword) => {
    return userPassword ? true : false
  }

  const handleErrorMessage = (message) => {
    if (message.includes('INACTIVE_FAILED_ATTEMPTS')) {
      setErrorMessage(t('messages:attemptsReached'))
    } else if (message.includes('EXPIRED_PASSWORD')) {
      setErrorMessage(t('messages:passwordExpired'))
    } else if (message.includes('INACTIVE_BY_NOLOGIN')) {
      setErrorMessage(t('messages:userBlocked'))
    } else {
      setErrorMessage(message)
    }
  }

  const handleInputUserEmail = (evt) => {
    const newUserEmail = evt.target.value
    if (validateUserEmail(newUserEmail)) {
      setIsUserEmailOK(true)
    } else {
      setIsUserEmailOK(false)
    }
    setUserEmail(newUserEmail)
  }

  const handleInputUserPassword = (evt) => {
    const newUserPassword = evt.target.value
    if (validateUserPassword(newUserPassword)) {
      setIsUserPasswordOK(true)
    } else {
      setIsUserPasswordOK(false)
    }
    setUserPassword(newUserPassword)
  }

  const handleKeyPress = (evt) => {
    if (evt.key === 'Enter' && isSignUpBtnOK) {
      handleSignInBtn()
    }
  }

  const handleClickShowPassword = () => {
    sestShowPassword(() => !showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleSignInBtn = async () => {
    if (isSignUpBtnOK) {
      const urlLogin = `${process.env.REACT_APP_BASEURL}/auth/login`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userEmail,
          password: userPassword,
        }),
      }

      fetch(urlLogin, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 400) {
            setUPError(true)
          } else if (!res.success && !res.access_token) {
            setUPError(true)
            handleErrorMessage(res.message)
          }

          if (res.access_token) {
            setUserServices(res.access_token)
          } else {
            throw Error(t('messages:unexpectedError'))
          }
        })
        .catch((error) => {
          console.error('[LOGIN ERROR] --> ', error)
          setUPError(true)
        })
    }
  }

  const setUserServices = (token) => {
    const decoded = jwt_decode(token)
    const { emailClient, roles } = decoded
    const formattedRoles = roles.join(', ')
    ReactGA.set({ userId: `${decoded?.email}-${decoded?.emailClient}` })

    const urlLogin = `${process.env.REACT_APP_BASEURL}/admin/roles/subservices?emailClient=${emailClient}&roles=${formattedRoles}`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    fetch(urlLogin, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode >= 300) {
          setUPError(true)
        } else if (!res.success && typeof res.data === 'string') {
          throw Error(res.data)
        } else if (!res.success && !Array.isArray(res)) {
          throw Error(res.message)
        } else if (res && res.message) {
          dispatch(performSetServices([]))
        } else {
          dispatch(performSetServices(res))
          handleUserLogin({ access_token: token })
          setUPError(false)
          handleErrorMessage('')
        }
      })
      .catch((error) => {
        console.error('[LOGIN ERROR] --> ', error)
        setUPError(true)
        handleErrorMessage(error.message)
      })
  }

  return (
    <Box className="login" sx={{ backgroundColor: theme === 'light' ? '#ecefed' : '#242524' }}>
      <Paper className="login-wrapper" variant="colorSecondary" elevation={0}>
        <Box>
          <img src={theme === 'light' ? GuenoLogo : GuenoLogoDark} alt="güeno-logo" style={{ height: 65 }} />
        </Box>

        <Box className="login-box">
          <InputLabel>{t('common:email')}:</InputLabel>
          <TextField
            value={userEmail}
            onChange={(evt) => handleInputUserEmail(evt)}
            onKeyDown={(evt) => handleKeyPress(evt)}
            placeholder={t('common:enterUserEmail')}
            fullWidth
            required
          />
        </Box>

        <Box className="login-box">
          <InputLabel>{t('common:password')}:</InputLabel>
          <TextField
            type={showPassword ? 'text' : 'password'}
            value={userPassword}
            fullWidth
            onChange={handleInputUserPassword}
            onKeyDown={(evt) => handleKeyPress(evt)}
            placeholder={t('common:enterPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {upError && errorMessage && (
          <Alert style={{ marginBottom: 8 }} onClose={() => setUPError(false)} severity="error">
            {errorMessage}
          </Alert>
        )}
        <Button disabled={!isSignUpBtnOK} onClick={handleSignInBtn} variant="contained">
          {t('common:signIn')}
        </Button>
      </Paper>
    </Box>
  )
}

export default Login
