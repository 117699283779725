import { Box, InputLabel, Paper, Typography } from '@mui/material'
import './createRulePreview.scss'
import React from 'react'
import { camelCaseFormatter } from '../../../utilities/formatters/camelCase'
import { useTranslation } from 'react-i18next'
import { mapCodesToNames } from '../../../utilities/MapCodesToNames'

const CreateRulePreview = ({
  ruleParameters,
  filters,
  selectedAction,
  ras = {},
  checklist = {},
  isGueno,
  isUniqueAlert,
  workflowConsumerId,
  workflowBusinessId,
  customCategories = [],
}) => {
  const { t } = useTranslation(['common'])

  const showParameter = (parameter) => {
    let result = true
    if (parameter === '') result = false
    else if (Array.isArray(parameter) && parameter.length === 0) result = false
    else if (typeof parameter === 'object') {
      let aux = []
      Object.keys(parameter).forEach((element) => {
        if (parameter[element] === '') aux.push(false)
        if (parameter[element] === 0) aux.push(false)
        else if (Array.isArray(parameter[element]) && parameter.length === 0) aux.push(false)
        else aux.push(true)
      })
      if (aux.length !== 0 && !aux.includes(true)) result = false
    }
    return result
  }

  const renderData = (data) => {
    if (Array.isArray(data)) {
      return (
        <Box>
          {data.map((item, idx) => {
            return (
              <Box sx={{ display: 'flex', gap: '.5rem' }}>
                <InputLabel key={idx}>{idx + 1}:</InputLabel>
                <Typography variant="subtitle4" key={idx}>
                  {renderData(mapCodesToNames(item))}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )
    } else if (typeof data === 'object' && data !== null) {
      return (
        <Box className="render-data-box">
          {Object.entries(data).map(([key, value], idx) => {
            return (
              <Box className="render-data-items" key={idx}>
                {key !== 'dateInit' && key !== 'dateEnd' && (
                  <ul>
                    <Box className="render-data-items-row">
                      <InputLabel>{camelCaseFormatter(key)}:</InputLabel> {renderData(mapCodesToNames(value))}
                    </Box>
                  </ul>
                )}
              </Box>
            )
          })}
        </Box>
      )
    } else {
      return <Typography variant="subtitle3">{!data ? '0' : data.toString().split(',').join(', ')}</Typography>
    }
  }

  const renderChecklistData = (key, value) => {
    return value &&
      ((typeof value === 'string' && value.trim() !== '' && value?.length > 0) ||
        (Array.isArray(value) && value.length > 0)) ? (
      <Box key={`${key}-1`} className="preview-item">
        <Typography variant="subtitle3">{key}</Typography>
        <Box key={`${key}-2`}>
          {key !== 'actions'
            ? value
            : Array.isArray(value)
            ? value.map((el, idx) => {
                return (
                  <Box key={`${key}-${idx}`}>
                    {
                      <Box className="checklist-actions">
                        <InputLabel>{idx + 1}:</InputLabel> {el}
                      </Box>
                    }
                  </Box>
                )
              })
            : 'No actions'}
        </Box>
      </Box>
    ) : (
      <></>
    )
  }

  return (
    <Box className="rule-drawer-section-box">
      {Array.isArray(customCategories) && customCategories.length > 0 && (
        <Paper elevation={0} variant="rootOutlined" className="rule-drawer-section-box">
          <Typography variant="title">{t('common:customCategories')}</Typography>
          <Paper elevation={0} variant="colorPrimary">
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.75rem', px: 2, py: 1 }}>
              {customCategories.map((category, index) => (
                <Typography key={index} variant="title2" sx={{ fontWeight: 600, fontSize: '1rem' }}>
                  {category?.label || category}
                </Typography>
              ))}
            </Box>
          </Paper>
        </Paper>
      )}
      <Paper elevation={0} variant="rootOutlined" className="rule-drawer-section-box">
        <Typography variant="title">{t('common:filters')}</Typography>
        {isGueno && (!Array.isArray(filters) || filters.length === 0) && (
          <Typography variant="title3">{t('common:noFilters')}</Typography>
        )}
        {Array.isArray(filters) &&
          filters.map((item, index) => (
            <Box key={index}>
              {showParameter(item[Object.keys(item)[0]]) && (
                <Paper elevation={0} variant="colorPrimary">
                  <Typography variant="subtitle3">{camelCaseFormatter(Object.keys(item)[0])}</Typography>
                  <Box>{renderData(item[Object.keys(item)[0]])}</Box>
                </Paper>
              )}
            </Box>
          ))}
        {!isGueno && !Array.isArray(filters) && Object.keys(filters).length === 0 && (
          <Typography variant="title3">{t('common:noFilters')}</Typography>
        )}
        {!Array.isArray(filters) &&
          Object.keys(filters).map((item, index) => (
            <Box key={index}>
              {showParameter(filters[item]) && (
                <Box className="preview-item">
                  <Typography variant="subtitle3">{camelCaseFormatter(item)}</Typography>
                  <Box>{renderData(filters[item])}</Box>
                </Box>
              )}
            </Box>
          ))}
      </Paper>

      <Paper elevation={0} variant="rootOutlined" className="rule-drawer-section-box">
        <Typography variant="title">{t('common:parameters')}</Typography>
        {Object.keys(ruleParameters).length === 0 && (
          <Typography variant="title3">{t('common:noParameters')}</Typography>
        )}
        {Object.keys(ruleParameters).map((item, index) => (
          <Box key={index}>
            {showParameter(ruleParameters[item]) && (
              <Paper elevation={0} variant="colorPrimary">
                <Typography variant="subtitle3">{camelCaseFormatter(item)}</Typography>
                <Box>{renderData(ruleParameters[item])}</Box>
              </Paper>
            )}
          </Box>
        ))}
      </Paper>
      {checklist?.isChecklistOk && (
        <Paper elevation={0} variant="rootOutlined" className="rule-drawer-section-box">
          <Typography variant="title">{t('common:checklists')}</Typography>
          {!checklist?.id && <Typography variant="title3">{t('common:noChecklists')}</Typography>}
          {checklist?.id && (
            <Paper elevation={0} variant="colorPrimary">
              {Object.entries(checklist)
                .filter((cl) => cl[0] === 'title' || cl[0] === 'description' || cl[0] === 'actions')
                .map((cl) => renderChecklistData(cl[0], cl[1]))}
            </Paper>
          )}
        </Paper>
      )}
      <Paper elevation={0} variant="rootOutlined" className="rule-drawer-section-box">
        <Typography variant="title">{t('common:ruleAdvancedSettings')}</Typography>
        {(!ras?.usersToUpdate || ras?.usersToUpdate === '') &&
          (!ras?.txStateUpdate || ras?.txStateUpdate?.updateTo === '') && (
            <Typography variant="title3">{t('common:noAdvancedSettings')}</Typography>
          )}
        {ras?.usersToUpdate && (
          <>
            <Paper elevation={0} variant="colorPrimary">
              <Typography variant="subtitle3">{t('common:usersToUpdate')}</Typography>
              <Box>{ras?.usersToUpdate}</Box>
            </Paper>
            {ras?.userStatusUpdate?.updateTo && (
              <Paper elevation={0} variant="colorPrimary">
                <Typography variant="subtitle3">{t('common:updateUserState')}</Typography>
                <Box>{ras?.userStatusUpdate?.updateTo}</Box>
                {ras?.userStatusUpdate?.reason && (
                  <>
                    <Typography variant="subtitle3">{t('common:reason')}</Typography>
                    <InputLabel>{ras?.userStatusUpdate?.reason}</InputLabel>
                  </>
                )}
                {ras?.userStatusUpdate?.description && (
                  <>
                    <Typography variant="subtitle3">{t('common:description')}</Typography>
                    <InputLabel>{ras?.userStatusUpdate?.description}</InputLabel>
                  </>
                )}
              </Paper>
            )}
            {ras?.userKYCStatusUpdate?.updateTo && (
              <Paper elevation={0} variant="colorPrimary">
                <Typography variant="subtitle3">{t('common:updateUserKyc')}</Typography>
                <Box>{ras?.userKYCStatusUpdate?.updateTo}</Box>
                {ras?.userKYCStatusUpdate?.reason && (
                  <>
                    <Typography variant="subtitle3">{t('common:reason')}</Typography>
                    <InputLabel>{ras?.userKYCStatusUpdate?.reason}</InputLabel>
                  </>
                )}
                {ras?.userKYCStatusUpdate?.description && (
                  <>
                    <Typography variant="subtitle3">{t('common:description')}</Typography>
                    <InputLabel>{ras?.userKYCStatusUpdate?.description}</InputLabel>
                  </>
                )}
              </Paper>
            )}
          </>
        )}
        {ras?.txStateUpdate?.updateTo && (
          <Paper elevation={0} variant="colorPrimary">
            <Typography variant="subtitle3">{t('common:updateTxsStatus')}</Typography>
            <Box>{ras?.txStateUpdate?.updateTo}</Box>
            {ras?.txStateUpdate?.description && (
              <>
                <Typography variant="subtitle3">{t('common:description')}</Typography>
                <InputLabel>{ras?.txStateUpdate?.description}</InputLabel>
              </>
            )}
          </Paper>
        )}
      </Paper>
      <Paper elevation={0} variant="rootOutlined" className="rule-drawer-section-box">
        <Typography variant="title">{t('common:ruleAction')}</Typography>
        <Paper elevation={0} variant="colorPrimary">
          <Typography variant="subtitle3">Action</Typography>
          <InputLabel>{selectedAction}</InputLabel>
        </Paper>
      </Paper>

      <Paper elevation={0} variant="rootOutlined" className="rule-drawer-section-box">
        <Typography variant="title">{t('common:uniqueAlert')}</Typography>
        <Paper elevation={0} variant="colorPrimary">
          <Typography variant="subtitle3">{t('common:markedAsUniqueAlert')}</Typography>
          <InputLabel>{isUniqueAlert ? 'Yes' : 'NO'}</InputLabel>
        </Paper>
      </Paper>

      {isUniqueAlert && (workflowConsumerId || workflowConsumerId) && (
        <Paper elevation={0} variant="rootOutlined" className="rule-drawer-section-box">
          <Typography variant="title">{t('common:defaultWorkflowAssignment')}</Typography>
          {workflowConsumerId && (
            <Paper elevation={0} variant="colorPrimary">
              <Typography variant="subtitle3">{t('common:consumer')}</Typography>
              <InputLabel>{workflowConsumerId ? workflowConsumerId : '---'}</InputLabel>
            </Paper>
          )}
          {workflowBusinessId && (
            <Paper elevation={0} variant="colorPrimary">
              <Typography variant="subtitle3">{t('common:business')}</Typography>
              <InputLabel>{workflowBusinessId ? workflowBusinessId : '---'}</InputLabel>
            </Paper>
          )}
        </Paper>
      )}
    </Box>
  )
}

export default CreateRulePreview
