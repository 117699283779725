import './Tabs.scss'
import { Box, TableContainer } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataGridPro } from '@mui/x-data-grid-pro'
import CustomColumnMenu from '../../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../../components/common/datagrid/CustomLoadingOverlay'
import Spinner from '../../../../components/common/spinner/spinner'
import { buildTimeStringFromTimestamp } from '../../../../components/common/time/timeHelper'
import { TableChip } from '../../../../components/utilities/TableChip'

const TabReports = ({ isLoading, reports }) => {
  const { t } = useTranslation(['common'])

  const columns = [
    {
      field: 'email',
      headerName: t('common:user'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <Box>{params.row.email}</Box>
      },
    },
    {
      field: 'closed',
      headerName: t('common:status'),
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return <TableChip noIcon={true} action={params.row.closed ? 'CLOSED' : 'OPEN'} />
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return buildTimeStringFromTimestamp(params.row.createdAt)
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return buildTimeStringFromTimestamp(params.row.updatedAt)
      },
    },
  ]

  const getDetailPanelContent = ({ row }) => {
    return (
      <Box key={row._id} className="tabs-expandable-container">
        <Box className="modal-box-filters">
          <Box dangerouslySetInnerHTML={{ __html: row.description }} />
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {isLoading && <Spinner />}
      <Box className={`${isLoading && 'spinner-transition'} datatable`}>
        <TableContainer>
          <Box className="grid-table-container">
            <DataGridPro
              getRowHeight={() => 'auto'}
              getRowId={(row) => row._id || row.id}
              rows={(Array.isArray(reports) && reports.length && reports) || []}
              columns={columns}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={() => 'auto'}
              slots={{
                columnMenu: CustomColumnMenu,
                loadingOverlay: CustomLoadingOverlay,
              }}
              hideFooter={true}
              autoHeight
              sortingMode="server"
              loading={isLoading}
            />
          </Box>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default TabReports
