import './Tabs.scss'
import { Box, IconButton, TableContainer, TablePagination } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataGridPro } from '@mui/x-data-grid-pro'
import CustomColumnMenu from '../../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../../components/common/datagrid/CustomLoadingOverlay'
import Spinner from '../../../../components/common/spinner/spinner'
import { buildTimeStringFromTimestamp } from '../../../../components/common/time/timeHelper'
import { KybChip } from '../../../../components/utilities/KybChip'
import { useState } from 'react'
import { useGetTimelineByCaseQuery } from '../../../../features/cases/caseSlice'
import { useParams } from 'react-router-dom'
import { useGridApiRef } from '@mui/x-data-grid'
import RefreshIcon from '@mui/icons-material/Refresh'

const TabTimeline = () => {
  const { id } = useParams()
  const { t } = useTranslation(['common'])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const apiRef = useGridApiRef()

  const {
    data: resultsTimeline,
    isLoading: isLoadingTimeline,
    isFetching,
    refetch,
  } = useGetTimelineByCaseQuery({
    id,
    page,
    limit: rowsPerPage,
  })

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
  }

  const getStyles = (type) => {
    let color
    let backgroundColor

    switch (type.toLowerCase()) {
      case 'creation':
        backgroundColor = '#4184c3'
        color = '#fafafa'
        break
      case 'new_transaction':
        backgroundColor = '#0dde4f'
        color = '#494b5a'
        break
      case 'reopen':
        backgroundColor = '#bfb1f2'
        color = '#494b5a'
        break
      case 'report':
        backgroundColor = '#a3efa8'
        color = '#494b5a'
        break
      case 'file':
        backgroundColor = '#f2b1d9'
        color = '#494b5a'
        break
      case 'comment':
        backgroundColor = '#b18cd4'
        color = '#fafafa'
        break
      default:
        backgroundColor = '#fdb72b'
        color = '#494b5a'
        break
    }

    return { color, backgroundColor }
  }

  const columns = [
    {
      field: 'type',
      headerName: t('common:type'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const { color, backgroundColor } = getStyles(params.row.type)
        return (
          <Box title={t(`common:${params.row.type}`)}>
            <KybChip action={t(`common:${params.row.type}`)} color={color} backgroundColor={backgroundColor} />
          </Box>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return buildTimeStringFromTimestamp(params.row.createdAt)
      },
    },
    {
      field: 'email',
      headerName: t('common:user'),
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'description',
      headerName: t('common:description'),
      flex: 1,
      minWidth: 200,
    },
  ]

  return (
    <Box>
      {isFetching && <Spinner />}
      <Box className={`${isFetching && 'spinner-transition'} datatable`}>
        <TableContainer>
          <Box className="tabs-top">
            <Box className="tabs-position-2">
              <TablePagination
                labelRowsPerPage={[]}
                component="div"
                count={resultsTimeline?.data?.pagination?.totalElements || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
              />
            </Box>
            <Box className="tabs-position-3">
              <IconButton
                onClick={() => {
                  if (apiRef.current) apiRef.current.setExpandedDetailPanels([])
                  refetch()
                }}
                disabled={isLoadingTimeline || isFetching}
                title={t('common:refresh')}
              >
                <RefreshIcon color="action" />
              </IconButton>
            </Box>
          </Box>
          <DataGridPro
            getRowHeight={() => 'auto'}
            getRowId={(row) => row._id || row.id}
            rows={
              (Array.isArray(resultsTimeline?.data?.timeline) &&
                resultsTimeline?.data?.timeline.length &&
                resultsTimeline?.data?.timeline) ||
              []
            }
            apiRef={apiRef}
            columns={columns}
            getDetailPanelHeight={() => 'auto'}
            slots={{
              columnMenu: CustomColumnMenu,
              loadingOverlay: CustomLoadingOverlay,
            }}
            hideFooter={true}
            autoHeight
            sortingMode="server"
            loading={isLoadingTimeline}
          />
        </TableContainer>
      </Box>
    </Box>
  )
}

export default TabTimeline
