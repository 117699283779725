import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'

const buildCasesParams = (query = {}) => {
  const { searchText, country, timezone = -3 } = query
  const params = {
    ...(searchText && { text: searchText }),
    ...(country && { country }),
  }
  return params
}

export const defaultKymFilters = {
  fromDate: null,
  toDate: null,
  searchText: '',
  country: '',
}

export const defaultKymParams = {
  page: 0,
  limit: 10,
  offset: 0,
  ...defaultKymFilters,
}

export const kymBusinessSlice = createSlice({
  name: 'kym',
  initialState: {
    filters: {
      ...defaultKymParams,
    },
  },
  reducers: {
    setCurrentCountry(state, action) {
      state.filters.country = action.payload
    },
  },
})

const endpoints = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findKymBusiness: builder.query({
      query: ({ limit, page, offset, sortField, sortDirection, ...query }) => {
        const params = buildCasesParams(query)
        return {
          url: `/kym/business`,
          params: {
            limit,
            page,
            offset,
            sortField,
            sortDirection,
            ...params,
          },
        }
      },
      providesTags: ['kymBusiness'],
    }),
  }),
})

export default kymBusinessSlice.reducer
export const { setCurrentCountry } = kymBusinessSlice.actions

export const { useFindKymBusinessQuery } = endpoints
