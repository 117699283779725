import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import DatatableOppositionControl from './DatatableOppositionControl'
import Spinner from '../../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import { useRequestsToAoR } from '../../pages-hooks/useRequestsToAoR'
import { updateRequestsToAoRFilters } from '../../../features/requestsToAoRSlice/requestsToAoRSlice'
import './oppositionControl.scss'

const OppositionControl = () => {
  const navigate = useNavigate()
  const filters = useSelector((state) => state.requestsToAoR.filters)
  const [requestedBy, setRequestedBy] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [requestStatus, setRequestStatus] = useState('')
  const [requestId, setRequestId] = useState('')
  const [requestType, setRequestType] = useState('EVALUATED_BY_ME')

  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const dispatch = useDispatch()
  const { t } = useTranslation(['common']) // TODO: acomodar uso en varias partes

  useRequestsToAoR(requestType, isLoading, setIsLoading)

  const handleResetButton = () => {
    setRequestId('')
    setRequestedBy('')
    setSelectedDate(null)
    setRequestStatus('')
    setIsLoading(true)

    dispatch(
      updateRequestsToAoRFilters({
        requestId: null,
        requestedBy: null,
        evaluatedBy: null,
        fromDate: null,
        toDate: '',
        page: 0,
        limit: 50,
        offset: 0,
      }),
    )

    navigate('/admin/oppositionControl')
  }

  const handleDispatch = () => {
    setPage(0)
    setIsLoading(true)
    return dispatch(
      updateRequestsToAoRFilters({
        requestId,
        requestedBy,
        fromDate: selectedDate?.toISOString(),
        toDate: new Date(toDate)?.toISOString(),
        ...filters,
      }),
    )
  }

  const handleTablePagination = () => {
    setIsLoading(true)

    return dispatch(
      updateRequestsToAoRFilters({
        ...filters,
        page,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        status: requestStatus,
      }),
    )
  }

  useEffect(() => {
    handleTablePagination()
  }, [rowsPerPage, page])

  /*   useEffect(() => {
    if (specificRequest) {
      let filters = { ...currentFilters }
      filters['requestId'] = specificRequest
      setIsLoading(true)
      dispatch(updateRequestsToAoRFilters(filters))
    }
  }, []) */

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [selectedDate])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner noTransform />}
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:oppositionControl')}</Typography>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <DatatableOppositionControl
          setIsLoading={setIsLoading}
          requestStatus={requestStatus}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleResetButton={handleResetButton}
          handleDispatch={handleDispatch}
          setRequestStatus={setRequestStatus}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          requestedBy={requestedBy}
          setRequestedBy={setRequestedBy}
          setRequestType={setRequestType}
          requestType={requestType}
        />
      </Box>
    </Box>
  )
}

export default OppositionControl
