import { Box, Divider, Paper, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { SelectInputForm, TextInputForm, RiskRangeNumberInputForm } from '../../../../components/common/form'
import { LoadingButton } from '@mui/lab'
import RiskMatrixParameterV2 from './RiskMatrixPrameterV2'

const rangeColors = {
  low: '#19db55',
  medium: '#ffa641',
  high: '#ff8a00',
  veryHigh: '#ff2b1b',
}

const RiskMatrixForm = ({ defaultValues, onSubmit, isEdit = false, isLoading = false }) => {
  const { t } = useTranslation(['common', 'messages'])

  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    target: yup.string().required(),
    country: yup.string().required(),
    ranges: yup.object().shape({
      low: yup.object().shape({
        min: yup.number().min(0).required(),
        max: yup.number().required(),
        action: yup.string().optional(),
      }),
      medium: yup.object().shape({
        min: yup.number().required(),
        max: yup.number().required(),
        action: yup.string().optional(),
      }),
      high: yup.object().shape({
        min: yup.number().required(),
        max: yup.number().required(),
        action: yup.string().optional(),
      }),
      veryHigh: yup.object().shape({
        min: yup.number().required(),
        max: yup.number().required(),
        action: yup.string().optional(),
      }),
    }),
    params: yup.object().optional(),
  })

  const { handleSubmit, control, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const [ranges, country, target] = watch(['ranges', 'country', 'target'])

  const totalRange = 100
  const calculateWidth = (rangeKey) => {
    const width = ((ranges[rangeKey].max - ranges[rangeKey].min) / totalRange) * 100
    return `${width}%`
  }

  const handleSubmitWhitParmas = ({ params, ...values }) => {
    onSubmit({ ...values, params: params.rules })
  }

  return (
    <Paper elevation={0} variant="rootOutlined" sx={{ marginRight: '1rem', padding: '1.5rem 1rem 1.5rem 1.5rem' }}>
      <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
        <LoadingButton
          loading={isLoading}
          disabled={!formState.isDirty}
          variant="contained"
          onClick={handleSubmit(handleSubmitWhitParmas)}
        >
          {isEdit ? t('common:update') : t('common:create')}
        </LoadingButton>
      </Box>

      <Box className="matrix-manual-wrapper">
        <Box className="matrix-manual-flex">
          <TextInputForm
            name="name"
            control={control}
            label={t('common:name')}
            placeholder={`${t('common:insert')} ${t('common:name')}`}
            required
          />
          <TextInputForm
            name="description"
            control={control}
            label={t('common:description')}
            placeholder={`${t('common:insert')} ${t('common:description')}`}
          />
          <SelectInputForm
            name="target"
            label={t('common:targetEntity')}
            control={control}
            defaultValue="KYB"
            options={[
              { label: 'KYB', value: 'KYB' },
              { label: 'Files Person', value: 'files_person' },
            ]}
            emptyLabel={`${t('common:select')} ${t('common:targetEntity')}`}
            disabled={isEdit}
            required
          />
          <SelectInputForm
            name="country"
            label={t('common:country')}
            control={control}
            options={['BR', 'AR'].map((c) => ({ label: c, value: c }))}
            emptyLabel={`${t('common:select')} ${t('common:country')}`}
            disabled={isEdit}
            required
          />
        </Box>

        <Divider />

        <Box sx={{ gridColumn: '1 / 4' }}>
          <Typography variant="title">{t('common:riskLevelScoreTitle')}</Typography>
        </Box>

        <Box sx={{ width: '60%', display: 'flex', flexFlow: 'column', gap: 2 }}>
          <Box className="matrix-manual-flex">
            {['low', 'medium', 'high', 'veryHigh'].map((range) => (
              <RiskRangeNumberInputForm
                key={range}
                rangesName="ranges"
                range={range}
                label={t(`common:${range}`)}
                control={control}
              />
            ))}
          </Box>
          <Box className="matrix-level-bar">
            {Object.keys(ranges).map((key) => (
              <Box
                className="matrix-level-bar-item"
                key={key}
                style={{
                  width: calculateWidth(key),
                  backgroundColor: rangeColors[key],
                  transition: 'width 0.3s ease',
                }}
              >
                <Typography variant="title2">{t(`common:${key}`)}</Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="title">{t('common:attributeAggregation')}</Typography>
            {formState.errors?.params && (
              <Typography variant="caption" color="red">
                {formState.errors.params.message}
              </Typography>
            )}
          </Box>
        </Box>
        <RiskMatrixParameterV2 control={control} target={target} country={country} />
      </Box>
    </Paper>
  )
}

export default RiskMatrixForm
