export const RuleStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const RuleType = {
  KYT_TRANSACTION: 'KYT_TRANSACTION',
  KYT_USER: 'KYT_USER',
  RULE_ANALYSIS: 'RULE_ANALYSIS',
  DOCUMENT_VERIFICATION: 'DOCUMENT_VERIFICATION',
}

export const RuleId = {
  RG15: 'RG-15',
}
