import './home.scss'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Fab, Modal, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { Masonry } from '@mui/lab'
import { decodeToken } from 'react-jwt'

import { acceptExpirationPasswordMessage } from '../../features/auth/loginSlice'
import AlertsByRuleInstanceIds from './components/AlertsByRuleInstanceIds'
import AlertsByRuleId from './components/AlertsByRuleId'
import CasesStats from './components/CasesStats'
import HeaderPanel from './components/HeaderPanel'
import CasesByCategory from './components/CasesByCategory'

const Home = () => {
  const { t } = useTranslation(['messages', 'common'])
  const dispatch = useDispatch()
  const { accessToken, expirationMessageAccepted } = useSelector((state) => state.login)
  const decodedToken = decodeToken(accessToken)
  const [open, setOpen] = useState(!expirationMessageAccepted && decodedToken?.timeToExpirationPassword ? true : false)

  const handleClose = () => {
    setOpen(false)
    dispatch(acceptExpirationPasswordMessage(true))
  }

  return (
    <Box className="overview" style={{ padding: '0px 30px' }}>
      <Grid container columnSpacing={4} rowSpacing={4}>
        <Grid xs={12}>
          <HeaderPanel />
        </Grid>
      </Grid>
      <Grid container columnSpacing={4} rowSpacing={4}>
        <Grid xs={12}>
          <CasesStats />
        </Grid>
      </Grid>
      <Masonry columns={2} spacing={2}>
        <CasesByCategory />
        <AlertsByRuleInstanceIds />
        <AlertsByRuleId />
      </Masonry>

      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:attention')}!</Typography>
            </Box>
            <Fab variant="close" onClick={() => handleClose()}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box">
            <Box className="modal-flex">
              <Typography>{t('messages:passwordExpire')}</Typography>
              <Typography color="primary">{Math.trunc(decodedToken?.timeToExpirationPassword)}</Typography>
              <Typography>{t('common:days')}.</Typography>
            </Box>
            <Box className="modal-filter-buttons">
              <Button variant="contained" onClick={handleClose}>
                {t('common:Ok')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Home
