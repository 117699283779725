import '../../home/home.scss'
import './transaction.scss'
import './transactionDetail.scss'
import { Box, Typography, Paper, Link, Fab, Chip, Tab, Button, IconButton } from '@mui/material'
import Spinner from '../../../components/common/spinner/spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { useTransactionDetails } from '../../pages-hooks/useTransactions'
import { useSelector } from 'react-redux'
import { renderNames } from '../../../components/common/toRender/renderNames'
import { toCustomUTCDateString } from '../../../components/common/time/timeHelper'
import { Flags } from '../../../components/common/flag/flags'
import { TableChip } from '../../../components/utilities/TableChip'
import DatatableTags from '../../../components/common/datatable/DatatableTags'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { useTranslation } from 'react-i18next'
import { TableIcons } from '../../../components/utilities/TableIcons'
import { useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import DatatableEvent from '../../../components/common/datatable/DatatableEvent'
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined'
import GeneralAlertsDatatable from '../../case-management/alerts/GeneralAlertsTable'
import { selectUserMaster } from '../../../features/kyb/kybCountrySlice'

const TransactionDetail = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()
  const { t } = useTranslation(['common', 'messages'])
  const [selectedTab, setSelectedTab] = useState('origin')
  const languageState = useSelector((state) => state.language.lang)

  const { emailClient } = useSelector((state) => state.login)
  const userMaster = useSelector((state) => selectUserMaster(state, emailClient))

  useTransactionDetails(id, setIsLoading)

  const navigate = useNavigate()

  const { transactionDetails, transactionDetailsOrigin, transactionDetailsDestination } = useSelector(
    (state) => state.transaction,
  )

  const {
    originAmountDetails,
    originPaymentDetails,
    originDeviceData,
    destinationDeviceData,
    destinationAmountDetails,
    destinationPaymentDetails,
    tags,
  } = transactionDetails ?? {}
  // let spinnerOn = !transactionDetails || transactionDetails?.transactionId !== id

  const toShow = (key) => {
    return (
      key !== '__v' &&
      key !== '_id' &&
      key !== 'email' &&
      key !== 'password' &&
      key !== 'hasApiKey' &&
      key !== 'deleted' &&
      key !== 'passwordLastChanged' &&
      key !== 'previousPasswords' &&
      key !== 'emailClient'
    )
  }

  const renderObject = (obj) => {
    if (obj) {
      return Object.entries(obj).map(([key, value]) => {
        if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
          return (
            <Box key={key}>
              <Typography variant="subtitle4">{key}</Typography>
              {renderObject(value)}
            </Box>
          )
        } else if (String(value).includes('[object Object]')) {
          return (
            <Box key={key}>
              <h4>{key.charAt(0).toUpperCase() + key.slice(1)}</h4>
              {value.map((element, index) => {
                return (
                  <Box key={index}>
                    <Typography variant="subtitle4">item {index + 1}</Typography>
                    {renderObject(element)}
                  </Box>
                )
              })}
            </Box>
          )
        } else {
          return (
            <Box key={key}>
              {toShow(key) && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Typography variant="subtitle4">{key.charAt(0).toUpperCase() + key.slice(1)}</Typography>
                  <Typography variant="h6">
                    {value.toString() === 'true' || value.toString() === 'false' ? value.toString() : value}
                  </Typography>
                </Box>
              )}
            </Box>
          )
        }
      })
    }
    return 'No report'
  }

  return (
    <Box>
      {isLoading && <Spinner />}
      <Box className="transaction-details">
        <Paper elevation={0} className="transaction-details-top">
          <Typography variant="title">{t('common:transactionDetail')}</Typography>

          <Box className="transaction-info">
            <Paper elevation={0} variant="colorPrimary" className="transaction-box">
              <Box className="transaction-id">
                <Box>
                  <Typography variant="subtitle1">{t('common:transactionId')}</Typography>
                  <Typography variant="number">
                    <Link>{transactionDetails?.transactionId}</Link>
                  </Typography>
                </Box>
                <Fab
                  size="small"
                  className="icon-global"
                  variant="action"
                  onClick={() => {
                    navigator.clipboard.writeText(transactionDetails?.transactionId)
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </Fab>
              </Box>
            </Paper>

            <Paper elevation={0} variant="colorPrimary" className="transaction-box">
              <Typography variant="subtitle1">{t('common:transactionState')}</Typography>
              <Box sx={{ width: 'fit-content' }}>
                <TableChip noIcon={true} action={transactionDetails?.transactionState?.toUpperCase() || '---'} />
              </Box>
            </Paper>

            <Paper elevation={0} variant="colorPrimary" className="transaction-box">
              <Typography variant="subtitle1">{t('common:transactionTime')}</Typography>
              <Typography variant="number">
                {toCustomUTCDateString(transactionDetails.timestamp, userMaster?.timezone)}
              </Typography>
            </Paper>

            <Paper elevation={0} variant="colorPrimary" className="transaction-box">
              <Typography variant="subtitle1">{t('common:transactionType')}</Typography>
              <Box sx={{ width: 'fit-content' }}>
                <TableIcons type={transactionDetails?.type?.toUpperCase() ?? '---'} />
              </Box>
            </Paper>
          </Box>
        </Paper>

        <TabContext value={selectedTab}>
          <Box className="transaction-details-bottom">
            <Paper
              elevation={0}
              variant="rootTransparent"
              className="transaction-details-top"
              style={{ height: 'fit-content' }}
            >
              <Typography variant="title">{t('common:sections')}</Typography>
              <TabList
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
                className="transaction-tablist"
                onChange={(e, newValue) => setSelectedTab(newValue)}
              >
                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'origin' ? <CompareArrowsOutlinedIcon /> : <></>}
                      label={t('common:originAndDestination')}
                      variant={selectedTab === 'origin' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="origin"
                ></Tab>
                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'tags' ? <LocalOfferOutlinedIcon /> : <></>}
                      label={t('common:tags')}
                      variant={selectedTab === 'tags' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="tags"
                />
                {/*           <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'alerts' ? <ReportProblemOutlinedIcon /> : <></>}
                      label={t('common:alerts')}
                      variant={selectedTab === 'alerts' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="alerts"
                /> */}
                {transactionDetails?.generalAlerts?.filter((item) => item.type === 'amlCrypto').length > 0 && (
                  <Tab
                    label={
                      <Chip
                        icon={selectedTab === 'amlAlerts' ? <ReportProblemOutlinedIcon /> : <></>}
                        label={t('common:amlAlerts')}
                        variant={selectedTab === 'amlAlerts' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                      />
                    }
                    value="amlAlerts"
                  />
                )}

                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'ruleAlerts' ? <ReportProblemOutlinedIcon /> : <></>}
                      label={t('common:transactionAlerts')}
                      variant={selectedTab === 'ruleAlerts' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="ruleAlerts"
                />

                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'events' ? <CloseFullscreenOutlinedIcon /> : <></>}
                      label={t('common:events')}
                      variant={selectedTab === 'events' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="events"
                />
              </TabList>
            </Paper>
            <Paper elevation={0}>
              <TabPanel value="origin" className="transaction-details-top">
                <Typography variant="title">{t('common:originAndDestination')}</Typography>

                <Box className="transaction-info-bottom">
                  <Paper elevation={0} variant="rootDashed" className="transaction-info-box">
                    <Box>
                      <Box className="transaction-id">
                        <Typography variant="title">
                          {transactionDetailsOrigin?.userDetails?.name
                            ? renderNames(transactionDetailsOrigin.userDetails.name, 'User')
                            : renderNames(transactionDetailsOrigin?.legalEntity, 'User')}
                        </Typography>

                        <Chip className="chip-origin" label="Origin (sender)" />
                      </Box>

                      <Box className="transaction-uid">
                        <Typography variant="subtitle3">UUID:</Typography>
                        <Button
                          disabled={!transactionDetails.originUser?._id || !transactionDetails?.originUserType}
                          onClick={() =>
                            navigate(
                              `/kyt/users/${transactionDetails?.originUserType}/${transactionDetails?.originUser?._id}`,
                            )
                          }
                        >
                          {transactionDetails?.originUserId || '---'}
                        </Button>
                        {transactionDetails?.originUserId && (
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(transactionDetails.originUserId)
                            }}
                          >
                            <ContentCopyOutlinedIcon style={{ height: '1rem', width: '1rem', color: '#2F89FA' }} />
                          </IconButton>
                        )}
                        {transactionDetails &&
                          transactionDetails.originUserId &&
                          emailClient &&
                          emailClient === 'bancodecomercio@gueno.com' && (
                            <Box className="items btn-t">
                              <Button
                                variant="outlinedGrey"
                                onClick={() =>
                                  window.open(
                                    `${process.env.REACT_APP_REVEAL_INFORMATION_URL}${transactionDetails.originUserId}`,
                                    '_blank',
                                  )
                                }
                              >
                                Reveal Information
                              </Button>
                            </Box>
                          )}
                      </Box>
                    </Box>

                    <Box className="transaction-info-bottom">
                      <Paper className="transaction-box-center" elevation={0} variant="colorTertiary">
                        <Typography variant="subtitle3">{t('common:amountSent')}</Typography>
                        <Typography variant="title">{`${
                          originAmountDetails?.transactionCurrency ? originAmountDetails?.transactionCurrency : '---'
                        } ${
                          originAmountDetails?.transactionAmount
                            ? new Intl.NumberFormat(languageState, {
                                minimumFractionDigits: 2,
                                useGrouping: true,
                              }).format(originAmountDetails?.transactionAmount)
                            : '---'
                        }`}</Typography>
                      </Paper>
                      <Paper className="transaction-box-center" elevation={0} variant="colorTertiary">
                        <Typography variant="subtitle3">{t('common:originCountry')}</Typography>
                        <Typography variant="title">{Flags(originAmountDetails?.country)}</Typography>
                      </Paper>

                      <Paper elevation={0} className="transaction-payment-details">
                        <Typography variant="subtitle1">{t('common:paymentDetails')}</Typography>

                        {renderObject(originPaymentDetails)}
                      </Paper>

                      {originDeviceData && (
                        <Paper elevation={0} className="transaction-payment-details">
                          <Typography variant="subtitle1">
                            {t('common:device')} {t('common:data')}
                          </Typography>

                          {renderObject(originDeviceData)}
                        </Paper>
                      )}
                    </Box>
                  </Paper>
                  <Paper elevation={0} variant="rootDashed" className="transaction-info-box">
                    <Box>
                      <Box className="transaction-id">
                        <Typography variant="title">
                          {transactionDetailsDestination?.userDetails?.name
                            ? renderNames(transactionDetailsDestination.userDetails.name, 'User')
                            : renderNames(transactionDetailsDestination?.legalEntity, 'User')}
                        </Typography>

                        <Chip className="chip-destination" label="Destination (receiver)" />
                      </Box>
                      <Box className="transaction-uid">
                        <Typography variant="subtitle3">UUID:</Typography>
                        <Button
                          disabled={!transactionDetails.destinationUser?._id || !transactionDetails.destinationUserType}
                          onClick={() =>
                            navigate(
                              `/kyt/users/${transactionDetails?.destinationUserType}/${transactionDetails?.destinationUser?._id}`,
                            )
                          }
                        >
                          {transactionDetails?.destinationUserId || '---'}
                        </Button>
                        {transactionDetails?.destinationUserId && (
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(transactionDetails.destinationUserId)
                            }}
                          >
                            <ContentCopyOutlinedIcon style={{ height: '1rem', width: '1rem', color: '#2F89FA' }} />
                          </IconButton>
                        )}
                        {transactionDetails &&
                          transactionDetails.destinationUserId &&
                          emailClient &&
                          emailClient === 'bancodecomercio@gueno.com' && (
                            <Box className="items btn-t">
                              <Button
                                variant="outlinedGrey"
                                onClick={() =>
                                  window.open(
                                    `${process.env.REACT_APP_REVEAL_INFORMATION_URL}${transactionDetails.destinationUserId}`,
                                    '_blank',
                                  )
                                }
                              >
                                Reveal Information
                              </Button>
                            </Box>
                          )}
                      </Box>
                    </Box>

                    <Box className="transaction-info-bottom">
                      <Paper className="transaction-box-center" elevation={0} variant="colorTertiary">
                        <Typography variant="subtitle3">{t('common:amountSent')}</Typography>
                        <Typography variant="title">{`${
                          destinationAmountDetails?.transactionCurrency
                            ? destinationAmountDetails?.transactionCurrency
                            : '---'
                        } ${
                          destinationAmountDetails?.transactionAmount
                            ? new Intl.NumberFormat(languageState, {
                                minimumFractionDigits: 2,
                                useGrouping: true,
                              }).format(destinationAmountDetails?.transactionAmount)
                            : '---'
                        }`}</Typography>
                      </Paper>
                      <Paper className="transaction-box-center" elevation={0} variant="colorTertiary">
                        <Typography variant="subtitle3">{t('common:destinationCountry')}</Typography>
                        <Typography variant="title">{Flags(destinationAmountDetails?.country)}</Typography>
                      </Paper>

                      <Paper elevation={0} className="transaction-payment-details">
                        <Typography variant="subtitle1">{t('common:paymentDetails')}</Typography>

                        {renderObject(destinationPaymentDetails)}
                      </Paper>

                      {destinationDeviceData && (
                        <Paper elevation={0} className="transaction-payment-details">
                          <Typography variant="subtitle1">
                            {t('common:destination')} {t('common:data')}
                          </Typography>

                          {renderObject(destinationDeviceData)}
                        </Paper>
                      )}
                    </Box>
                  </Paper>
                </Box>
              </TabPanel>

              <TabPanel value="tags" className="transaction-details-top">
                <Typography variant="title">{t('common:tags')}</Typography>
                {tags && <DatatableTags tags={tags} />}
              </TabPanel>

              {transactionDetails?.generalAlerts?.filter((item) => item.type === 'amlCrypto').length > 0 && (
                <TabPanel value="amlAlerts" className="transaction-details-top">
                  <Typography variant="title">{t('common:amlAlerts')}</Typography>
                  <GeneralAlertsDatatable
                    type={'amlCrypto'}
                    hideActions={true}
                    rows={transactionDetails?.generalAlerts?.filter((item) => item.type === 'amlCrypto')}
                    isFromDetail={true}
                  />
                </TabPanel>
              )}

              <TabPanel value="ruleAlerts" className="transaction-details-top">
                <Typography variant="title">{t('common:transactionAlerts')}</Typography>
                <GeneralAlertsDatatable
                  type={'transactionRule'}
                  hideActions={true}
                  rows={transactionDetails?.generalAlerts?.filter((item) => item.type === 'transactionRule')}
                  isFromDetail={true}
                />
              </TabPanel>

              <TabPanel value="events" className="transaction-details-top">
                <Typography variant="title">{t('common:events')}</Typography>
                <DatatableEvent transactionId={id} />
              </TabPanel>
            </Paper>
          </Box>
        </TabContext>
      </Box>
    </Box>
  )
}

export default TransactionDetail
