import { Box, Paper, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFindConsumptionsByTenantQuery } from '../../../features/client/clientSlice'
import DatatableClientServicesConsumptions from '../../../components/common/datatable/DatatableClientServicesConsumptions'

const ClientServicesConsumptions = () => {
  const queryState = useSelector((state) => state.clientConsumptions.filters)
  const { t } = useTranslation(['common'])
  const {
    data: consumptions,
    isLoading,
    isFetching,
    refetch,
  } = useFindConsumptionsByTenantQuery({
    ...queryState,
    groupBy: 'product,type,action,tenant',
    status: 'SUCCESS',
  })

  return (
    <Box className="filter">
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:consumptions')}</Typography>
        </Box>
      </Box>
      <Paper elevation={0}>
        <DatatableClientServicesConsumptions
          consumptions={consumptions}
          isLoading={isLoading || isFetching}
          refetch={refetch}
        />
      </Paper>
    </Box>
  )
}

export default ClientServicesConsumptions
