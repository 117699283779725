import './MyRules.scss'
import { useEffect, useState } from 'react'
import AlertFetchSpinner from '../../../common/alertFetchSpinner/alertFetchSpinner'
import { Box, Modal } from '@mui/material'
import Spinner from '../../../common/spinner/spinner'
import { useSelector } from 'react-redux'
import DataExportModal from '../../../common/dataExport/DataExportModal'
import { myRulesDataMapper, myRulesDataMapperForPDF } from '../../../common/dataExport/dataMappers/myRulesDataMapper'
import { RulesDrawer } from '../rulesLibrary/RulesDrawer'
import MyRulesTable from './MyRulesTable'
import { useTranslation } from 'react-i18next'

const MyRules = ({
  rules,
  isLoading,
  instanceRules,
  setIsLoading,
  setInstanceRules,
  verifyDeletion,
  verifyEdition,
  categories,
  setCategories,
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [fetchError, setFechError] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [message, setMessage] = useState('')
  const [selectedRule, setSelectedRule] = useState({})
  const [ruleToEdit, setRuleToEdit] = useState({})
  const [open, setOpen] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [ruleList, setRuleList] = useState([])
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const { accessToken } = useSelector((state) => state.login)
  const { t } = useTranslation(['common', 'messages'])

  useEffect(() => {
    if (Array.isArray(instanceRules) || (message && message.includes(t('messages:deletionSuccess')))) {
      setRuleList(instanceRules)
    }
  }, [instanceRules, message])

  useEffect(() => {
    if (selectedRule && Object.keys(selectedRule).length !== 0 && rules.length !== 0) {
      const schemaSelected = rules.filter((element) => {
        return element.id === selectedRule?.ruleId
      })
      setRuleToEdit(schemaSelected[0])
    }
  }, [selectedRule, rules])

  const handleNext = (rule) => {
    const selected = ruleList.filter((element) => {
      return element.id === rule
    })
    setSelectedRule(selected[0])
    setOpen(true)
  }

  const handleCopy = (rule) => {
    const selected = ruleList.filter((element) => {
      return element.id === rule
    })
    setSelectedRule(selected[0])
    setOpen(true)
    setIsCopy(true)
  }

  const handleRuleUpdate = (id, value, row, isGueno, rows, setRowsToShow, property = 'status') => {
    setFetched(false)
    setIsFetching(true)
    setMessage(`${t('messages:updateRule')} ${id}.`)

    if (!process.env.REACT_APP_IS_DEMO) {
      const aux = { ...row, instanceId: id }
      ;[
        ('createdAt', 'updatedAt', 'id', 'idConcat', 'gueno', 'logic', 'logicAggregationVariables', 'baseCurrency'),
      ].forEach((field) => delete aux[field])

      let url, method, data

      // Configurar URL, método y datos dependiendo de isGueno
      if (isGueno) {
        url = `${process.env.REACT_APP_BASEURL}/rules/${id}`
        data = { [property]: value }
        method = 'PATCH'
      } else {
        url = `${process.env.REACT_APP_BASEURL}/kyt/updateClientInstanceRule/${id}`
        data = { ...aux, [property]: value }
        method = 'POST'
      }

      const options = {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) {
            throw new Error(res.message)
          }
          setIsFetching(false)
          setFetched(true)
          setFechError(false)
          setMessage(res.message)

          setTimeout(() => {
            setFetched(false)
          }, 3000)

          setIsLoading(true)
        })
        .catch(() => {
          setIsFetching(false)
          setFetched(true)
          setFechError(true)
          setMessage(`${t('messages:updateRule')} ${id} ${t('common:error')}.`)
          setTimeout(() => {
            setFetched(false)
          }, 3000)
        })
    } else {
      // Modo demo, actualiza localmente
      setIsFetching(false)
      setFetched(true)
      setFechError(false)
      setMessage('Rule update success')

      const idx = ruleList.findIndex((r) => r.id === id)
      if (idx !== -1) {
        const updatedList = [
          ...ruleList.slice(0, idx),
          { ...ruleList[idx], [property]: value },
          ...ruleList.slice(idx + 1),
        ]
        setInstanceRules(updatedList)
      }

      setTimeout(() => {
        setFetched(false)
      }, 3000)
    }
  }

  const handleDelete = (id, isGueno) => {
    if (!process.env.REACT_APP_IS_DEMO) {
      let url = `${process.env.REACT_APP_BASEURL}/rules/${id}`

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
      setSelectedRule({})

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.status >= 400) throw Error(res.message)

          let aux = [...ruleList]

          let idx = aux.findIndex((element) => {
            return element.id === id.toString()
          })

          if (!res.isCPO && idx !== -1) {
            setInstanceRules([...aux.slice(0, idx), ...aux.slice(idx + 1)])
          }
          setSelectedRule({})
          setFetched(true)
          setFechError(false)
          setMessage(`${t('common:rule')} ${id} ${t('messages:deletionSuccess')}.`)
          setTimeout(() => {
            setFetched(false)
          }, 3000)
        })
        .catch(() => {
          setFetched(true)
          setFechError(true)
          setMessage(`${t('common:rule')} ${id} ${t('messages:deletionError')}.`)
          setTimeout(() => {
            setFetched(false)
          }, 3000)
        })
    } else {
      let aux = [...ruleList]

      let idx = aux.findIndex((element) => {
        return element.id === id.toString()
      })

      setInstanceRules([...aux.slice(0, idx), ...aux.slice(idx + 1)])
      setSelectedRule({})
      setFetched(true)
      setFechError(false)
      setMessage(`${t('common:rule')} ${id} ${t('messages:deletionSuccess')}.`)
      setTimeout(() => {
        setFetched(false)
      }, 3000)
    }
  }

  const handleArchive = (id) => {
    let url

    if (!process.env.REACT_APP_IS_DEMO) {
      url = `${process.env.REACT_APP_BASEURL}/rules/${id}`

      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ status: 'ARCHIVED' }),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.isCPO) {
            setFetched(true)
            setFechError(false)
            setMessage(res.message)
          } else {
            if (res.status >= 400) throw Error(res.message)

            let aux = [...ruleList]

            let idx = aux.findIndex((element) => {
              return element.id === id.toString()
            })

            if (idx !== -1) {
              setInstanceRules([...aux.slice(0, idx), ...aux.slice(idx + 1)])
            }

            setFetched(true)
            setFechError(false)
            setMessage(`${t('common:rule')} ${id} ${t('messages:archiveSuccess')}.`)
          }
          setTimeout(() => {
            setFetched(false)
          }, 3000)
        })
        .catch(() => {
          setFetched(true)
          setFechError(true)
          setMessage(`${t('common:rule')} ${id} ${t('messages:archiveError')}.`)
          setTimeout(() => {
            setFetched(false)
          }, 3000)
        })
    } else {
      // Demo mode logic
      let aux = [...ruleList]

      let idx = aux.findIndex((element) => {
        return element.id === id.toString()
      })

      setInstanceRules([...aux.slice(0, idx), { ...aux[idx], status: 'ARCHIVED' }, ...aux.slice(idx + 1)])
      setFetched(true)
      setFechError(false)
      setMessage(`${t('common:rule')} ${id} ${t('messages:archiveSuccess')}.`)
      setTimeout(() => {
        setFetched(false)
      }, 3000)
    }
  }

  return (
    <Box>
      {isLoading && <Spinner />}
      {isFetching && <AlertFetchSpinner message={message} spinner={true} />}
      {fetched && <AlertFetchSpinner message={message} error={fetchError} />}

      {!isLoading && (
        <MyRulesTable
          handler={handleNext}
          handlerCopy={handleCopy}
          rows={ruleList}
          handleRuleUpdate={handleRuleUpdate}
          deleteHandler={handleDelete}
          handleArchive={handleArchive}
          isFetching={isFetching}
          verifyEdition={verifyEdition}
          verifyDeletion={verifyDeletion}
          setIsLoading={setIsLoading}
          categories={categories}
          setCategories={setCategories}
        />
      )}
      {ruleList.length !== 0 && (
        <RulesDrawer
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          selectedRule={selectedRule}
          setSelectedRule={setSelectedRule}
          setOpen={setOpen}
          open={open}
          myRule={true}
          isCopy={isCopy}
          setIsCopy={setIsCopy}
          handleCopy={handleCopy}
          ruleToEdit={ruleToEdit}
          ruleList={ruleList}
          setInstanceRules={setInstanceRules}
          verifyEdition={verifyEdition}
        />
      )}
      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            setShow={setShowDownloadModal}
            data={ruleList}
            dataMapper={myRulesDataMapper}
            dataMapperForPDF={myRulesDataMapperForPDF}
            fileName={'my-rules'}
          />
        </div>
      </Modal>
    </Box>
  )
}

export default MyRules
