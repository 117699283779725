import { Box, TextField, Button, Typography, Paper } from '@mui/material'
import './checklist.scss'
import { useEffect, useState } from 'react'
import ListOfStrings from '../../../components/kyt/rules/ruleParameters2/listOfStrings/listOfStrings'
import { useDispatch, useSelector } from 'react-redux'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import { useTranslation } from 'react-i18next'
import { updateChecklists } from '../../../features/checklist/checklistSlice'

export const ChecklistDetails = ({
  check,
  isEdition,
  isEditing,
  setIsEditing,
  checklists,
  setChecklists,
  setOpen,
  ruleStep = false,
}) => {
  const { emailClient, accessToken } = useSelector((state) => state.login)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [actions, setActions] = useState([])
  const [actionsObject, setActionsObject] = useState({})
  const [error, setError] = useState(false)
  const [errorList, setErrorList] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [fetchError, setFetchError] = useState(false)
  const [fetched, setFetched] = useState(false)
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const checkListsState = useSelector((state) => state.checklists.checklists?.data)

  useEffect(() => {
    if (isEdition) {
      setTitle(check.title)
      setDescription(check.description)
      setActions(check.actions)
      setActionsObject({ actions: check.actions })
    }
  }, [check, isEdition])

  const handleReset = () => {
    if (isEdition) {
      setTitle(check.title)
      setDescription(check.description)
      setActions(check.actions)
      setActionsObject({ actions: check.actions })
    } else {
      setTitle('')
      setDescription('')
      setActions([])
      setActionsObject({})
      setOpen(false)
    }
  }

  useEffect(() => {
    setActions(actionsObject.actions)
  }, [actionsObject])

  const cantFetch = () => {
    if (title === '' || description === '' || !actions?.length) return true
    else if (isEdition) return !isEditing
    return false
  }

  const handleFetch = () => {
    setIsFetching(true)

    const url = `${process.env.REACT_APP_BASEURL}/admin/checklistTemplates${isEdition ? `/${check.id}` : ''}`

    const body = { title, description, actions }
    if (!isEdition) body['emailClient'] = emailClient

    const options = {
      method: isEdition ? 'PATCH' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    }

    fetch(url, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.isCPO) {
          setIsFetching(false)
          setFetched(true)
          setFetchError(false)
          setFetchMessage(res.message)
          setIsEditing(false)
          setTimeout(() => {
            setFetched(false)
            handleReset()
          }, 3000)
          return
        }

        setFetchError(false)
        setFetchMessage('Update checklist template success')
        setIsFetching(false)
        setIsEditing(false)
        let checkListsList = ruleStep ? [...checkListsState] : [...checklists]

        if (isEdition) {
          let idx = checkListsList.findIndex((element) => {
            return element.id === check.id
          })

          if (idx !== -1) {
            const updatedChecklist = [
              ...checkListsList.slice(0, idx),
              {
                ...check,
                ...body,
              },
              ...checkListsList.slice(idx + 1),
            ]
            setChecklists(updatedChecklist)
          }
        } else {
          const updatedChecklist = [
            {
              ...body,
              id: res.data?.id,
            },
            ...checkListsList,
          ]
          setChecklists(updatedChecklist)
          dispatch(updateChecklists({ data: updatedChecklist }))
          handleReset()
        }
      })
      .catch((error) => {
        console.error(`[HOOK: ${isEdition ? 'Update' : 'Create'} checklist error] --> `, error)
        setFetchError(true)
        setFetchMessage(error.message)
        setIsFetching(false)
        setIsEditing(false)
        handleReset()
      })
  }

  return (
    <Paper variant="rootTransparent" className="checklist-details-wrapper">
      {(isFetching || fetched) && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
      <Box className="checklist-details-block">
        <Typography variant="title">
          {t('common:checklist')} {t('common:parameters')}
        </Typography>
        <Box className="checklist-details-block">
          <Typography variant="title3">{t('common:title')}</Typography>
          <TextField
            id="name"
            type="text"
            disabled={isEdition ? !isEditing : false}
            size="small"
            value={title || ''}
            onChange={(event) => {
              event.preventDefault()
              setTitle(event.target.value)
            }}
          />
        </Box>
        <Box className="checklist-details-block">
          <Typography variant="title3">{t('common:description')}</Typography>
          <TextField
            multiline
            size="small"
            id="description"
            disabled={isEdition ? !isEditing : false}
            type="text"
            value={description || ''}
            onChange={(event) => {
              event.preventDefault()
              setDescription(event.target.value)
            }}
          />
        </Box>
      </Box>
      <Box className="checklist-details-block">
        <Typography variant="title">{t('common:actions')}</Typography>
        <ListOfStrings
          propertie={'actions'}
          ruleParameters={actionsObject}
          setRuleParameters={setActionsObject}
          isEditing={isEdition ? isEditing : true}
          setError={setError}
          errorList={errorList}
          setErrorList={setErrorList}
        />
      </Box>
      <Button variant="contained" disabled={cantFetch()} onClick={handleFetch}>
        {!isEdition ? 'Create' : 'Update'}
      </Button>
    </Paper>
  )
}
