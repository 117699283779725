import { useTranslation } from 'react-i18next'
import './tableChip.scss'
import './riskLevel.scss'
import { Box } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'

export const TableChip = ({ action, noIcon, border }) => {
  const { t } = useTranslation(['common'])

  const getStyleByAction = (act) => {
    switch (act) {
      case 'rejected':
        return {
          color: 'var(--error-dark)',
          backgroundColor: 'var(--error-light)',
        }
      case 'REJECTED':
        return {
          color: 'var(--error-dark)',
          backgroundColor: 'var(--error-light)',
        }
      case 'accepted':
        return {
          color: 'var(--success-dark)',
          backgroundColor: 'var(--success-light)',
        }
      case 'APPROVED':
        return {
          color: 'var(--success-dark)',
          backgroundColor: 'var(--success-light)',
        }
      case 'FLAG':
        return {
          color: 'var(--alert-light)',
          backgroundColor: 'var(--alert-dark)',
        }
      case 'FLAGGED':
        return {
          color: 'var(--alert-light)',
          backgroundColor: 'var(--alert-dark)',
        }
      case 'REFUND':
        return {
          color: 'var(--grey-75)',
          backgroundColor: 'var(--refunded)',
        }
      case 'SUSPEND':
        return {
          color: 'var(--calm-dark)',
          backgroundColor: 'var(--calm-light)',
        }
      case 'BLOCK':
        return {
          color: 'var(--error-dark)',
          backgroundColor: 'var(--error-light)',
        }
      case 'CREATED':
        return {
          color: 'var(--grey-70)',
          backgroundColor: 'var(--created)',
        }
      case 'PROCESSING':
        return {
          color: 'var(--grey-70)',
          backgroundColor: 'var(--processing)',
        }
      case 'SUCCESSFUL':
        return {
          color: 'var(--success-dark)',
          backgroundColor: 'var(--success-light)',
        }
      case 'SENT':
        return {
          color: 'var(--calm-dark)',
          backgroundColor: 'var(--calm-light)',
        }
      case 'SUSPENDED':
        return {
          color: 'var(--error-light)',
          backgroundColor: 'var(--error-dark)',
        }
      case 'REFUNDED':
        return {
          color: 'var(--grey-75)',
          backgroundColor: 'var(--refunded)',
        }
      case 'DECLINED':
        return {
          color: 'var(--grey-75)',
          backgroundColor: 'var(--declined)',
        }
      case 'OPEN':
        return {
          color: 'var(--info-dark)',
          backgroundColor: 'var(--info-light)',
        }
      case 'CLOSED':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--open)',
        }
      case 'PENDING':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--open)',
        }
      case 'REVIEW_IN_PROGRESS':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--open)',
        }
      case 'REVIEW_FINISHED':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--open)',
        }
      case 'CLOSED_WITH_ACTIONS':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--open)',
        }
      case 'CLOSED_WITHOUT_ACTIONS':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--open)',
        }
      case 'ACH':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--ach)',
        }
      case 'CARD':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--card)',
        }
      case 'IBAN':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--iban)',
        }
      case 'UPI':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--upi)',
        }
      case 'GENERIC_BANK_ACCOUNT':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--generic-bank-account)',
        }
      case 'MPESA':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--mpesa)',
        }
      case 'SWIFT':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--swift)',
        }
      case 'WALLET':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--wallet)',
        }
      case 'CHECK':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--check)',
        }
      case 'CBU':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--cbu)',
        }
      case 'CVU':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--cvu)',
        }
      case 'ECHECK':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--echeck)',
        }
      case 'DEBIN':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--debin)',
        }
      case 'QR_CODE':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--qr-code)',
        }
      case 'ONLINE_PAYMENT':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--online-payment)',
        }
      case 'TED':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--tes)',
        }
      case 'BOLETO':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--boleto)',
        }
      case 'PIX':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--pix)',
        }
      case 'clear':
        return {
          color: 'var(--success-dark)',
          backgroundColor: 'var(--success-light)',
        }
      case 'pending':
        return {
          color: 'var(--alert-dark)',
          backgroundColor: 'var(--alert-light)',
        }
      case 'attention':
        return {
          color: 'var(--alert-dark)',
          backgroundColor: 'var(--alert-light)',
        }
      case 'potential_match':
        return {
          color: 'var(--error-dark)',
          backgroundColor: 'var(--error-light)',
        }
      case 'no_match':
        return {
          color: 'var(--success-dark)',
          backgroundColor: 'var(--success-light)',
        }
      case 'identity_check':
        return {
          color: 'var(--calm-light)',
          backgroundColor: 'var(--calm-dark)',
        }
      case 'document_check':
        return {
          color: 'var(--info-light)',
          backgroundColor: 'var(--info-dark)',
        }
      case 'invalid':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--block)',
        }
      case 'valid':
        return {
          color: 'var(--success-dark)',
          backgroundColor: 'var(--success-light)',
        }
      case 'AML':
        return {
          color: 'var(--info-dark)',
          backgroundColor: 'var(--info-light)',
        }
      case 'FRAUD':
        return {
          color: 'var(--alert-dark)',
          backgroundColor: 'var(--alert-light)',
        }
      case 'BATCH_PROCESSED':
        return {
          color: 'var(--success-dark)',
          backgroundColor: 'var(--success-light)',
        }
      case 'BATCH_PARTIAL_PROCESSED':
        return {
          color: 'var(--calm-light)',
          backgroundColor: 'var(--calm-dark)',
        }
      case 'BATCH_PROCESSING':
        return {
          color: 'var(--calm-light)',
          backgroundColor: 'var(--calm-dark)',
        }
      case 'BATCH_READY':
        return {
          color: 'var(--info-dark)',
          backgroundColor: 'var(--info-light)',
        }
      case 'BATCH_ERROR':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--block)',
        }
      case 'yes':
        return {
          color: 'var(--error-dark)',
          backgroundColor: 'var(--error-light)',
        }
      case 'no':
        return {
          color: 'var(--alert-dark)',
          backgroundColor: 'var(--alert-light)',
        }
      case 'liveRule':
        return {
          color: 'var(--grey-70)',
          backgroundColor: 'var(--grey-40)',
        }
      case 'shadowRule':
        return {
          color: 'var(--grey-70)',
          backgroundColor: 'var(--grey-40)',
        }
      case 'BATCH_PROCESSED_WITH_ERRORS':
        return {
          color: 'var(--white)',
          backgroundColor: 'var(--block)',
        }

      default:
        return {
          color: 'var(--grey-75)',
          backgroundColor: 'inherit',
        }
    }
  }

  return (
    <Box
      style={{
        backgroundColor: getStyleByAction(action)?.backgroundColor,
        border: border ? '1px solid var(--gray-60)' : 'none',
      }}
      className="risk-level-icon"
    >
      {!noIcon && <CircleIcon style={{ color: getStyleByAction(action)?.color }} className="rl-icon" />}
      <p style={{ color: getStyleByAction(action)?.color }}>{t(`common:${action}`)}</p>
    </Box>
  )
}
