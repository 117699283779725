import { useMemo, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  TableContainer,
  TablePagination,
  TextField,
  Typography,
  Link,
  Button,
  Tooltip,
  Autocomplete,
} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { ClearIcon } from '@mui/x-date-pickers'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { Visibility } from '@mui/icons-material'

import styles from './styles.module.scss'

import { buildTimeStringFromTimestamp } from '../../components/common/time/timeHelper'
import useDebounce from '../../components/hooks/useDebounce'
import { countryDictionary } from '../../components/common/flag/countries'
import useUserTableConfig from '../../components/hooks/useUserTableConfig'
import {
  getOrderedColumns,
  getVisibilityModel,
  updateListOrder,
} from '../../components/common/datatable/tableUtils/tableUtils'
import CustomColumnMenu from '../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../components/common/datagrid/CustomLoadingOverlay'
import { setCurrentCountry, useFindKymBusinessQuery } from '../../features/kym/kymBusinessSlice'

const KymBusiness = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['messages', 'common'])
  const queryState = useSelector((state) => state.kymBusiness.filters)
  const [page, setPage] = useState(queryState.page)
  const country = queryState.country
  const [searchText, setSearchText] = useState()
  const searchTextDebounced = useDebounce(searchText, 500)

  const countryOptions = useMemo(() => {
    return Object.keys(countryDictionary).map((country) => ({
      value: country,
      label: countryDictionary[country],
    }))
  }, [])

  const columns = useMemo(() => [
    {
      field: 'externalId',
      headerName: t('common:externalId'),
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: '13px' }} variant="body2">
            {row.externalId || '---'}
          </Typography>
        )
      },
    },
    {
      field: 'name',
      headerName: t('common:name'),
      width: 350,
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: '13px' }} variant="body2">
            {row.identity.name || '---'}
          </Typography>
        )
      },
    },
    {
      field: 'documentNumber',
      headerName: t('common:documentNumber'),
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: '13px' }} variant="body2">
            {row.identity.documentNumber || '---'}
          </Typography>
        )
      },
    },
    {
      field: 'dateOfRegistration',
      headerName: t('common:dateOfRegistration'),
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: '13px' }} variant="body2">
            {row.identity.dateOfRegistration || '---'}
          </Typography>
        )
      },
    },
    {
      field: 'documentType',
      headerName: t('common:documentType'),
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: '13px' }} variant="body2">
            {row.identity.documentType || '---'}
          </Typography>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      width: 190,
      renderCell: ({ row }) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(row.createdAt)}</Typography>
      },
    },
    {
      field: 'kyb',
      headerName: t('kym.column.fileBusiness'),
      width: 200,
      sortable: false,
      renderCell: ({ row }) => (
        <Link component={ReactRouterLink} title={row._id} to={`/kyb/${row.country}/detail/${row.kyb}`}>
          {row.kyb}
        </Link>
      ),
    },
    {
      field: 'kyt',
      headerName: t('common:Kyt'),
      width: 180,
      sortable: false,
      renderCell: ({ row }) => {
        return row.kyt ? (
          <Link component={ReactRouterLink} title={row._id} to={`/kyt/users/business/${row.kyt}`}>
            {row.externalId}
          </Link>
        ) : (
          '---'
        )
      },
    },
  ])

  const {
    config,
    saveConfig,
    loading: configLoading,
  } = useUserTableConfig({
    tableKey: 'kym',
    defaultConfig: {
      sortModel: [{ field: 'createdAt', sort: 'desc' }],
      rowsPerPage: 10,
      pinnedColumns: { left: [], right: [] },
      columnOrder: columns.map((c) => c.field),
    },
  })

  const { field, sort } = useMemo(
    () => (config.sortModel?.length ? config.sortModel[0] : { field: '', sort: '' }),
    [config.sortModel],
  )

  const {
    isFetching,
    isLoading,
    refetch,
    data: kymBusiness,
  } = useFindKymBusinessQuery({
    limit: config.rowsPerPage,
    page,
    offset: config.rowsPerPage * page,
    searchText: searchTextDebounced,
    country,
    sortField: field || 'createdAt',
    sortDirection: sort === 'desc' ? -1 : 1,
  })

  const apiRef = useGridApiRef()

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    saveConfig({ rowsPerPage: newRowsPerPage })
    setPage(0)
  }

  const handleColumnOrderChange = (params) => {
    const updatedOrder = updateListOrder(config.columnOrder, params)
    if (JSON.stringify(config.columnOrder) !== JSON.stringify(updatedOrder)) {
      saveConfig({ columnOrder: updatedOrder })
    }
  }

  const orderedColumns = useMemo(
    () => getOrderedColumns(config.columnOrder, columns),
    [JSON.stringify(config.columnOrder), columns],
  )

  const visibleColumnsModel = useMemo(
    () => getVisibilityModel(config.visibleColumns, columns),
    [config.visibleColumns, columns],
  )

  return (
    <Box>
      <Box className={`${styles.container} filter`}>
        <Typography variant="h2" className={styles.pageTitle}>
          KYM - {t('common:knowYourManager')}
        </Typography>
        <Box className={styles.actionsContainer} sx={{ m: 1, gap: 2 }}>
          <TextField
            sx={{ width: 350 }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={t('common:searchByText')}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton alt="Clear" disabled={!searchText} onClick={() => setSearchText('')}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Autocomplete
            size="small"
            sx={{ width: 230 }}
            value={countryOptions.find((option) => option.value === country)}
            onChange={(_, newValue) => dispatch(setCurrentCountry(newValue?.value))}
            options={countryOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder={t('common:selectCountry')} />
            )}
            disablePortal
          />
        </Box>
      </Box>
      <Paper elevation={0}>
        <TableContainer>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 16px',
            }}
          >
            <TablePagination
              labelRowsPerPage={[]}
              component="div"
              count={kymBusiness?.pagination?.totalElements || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={config.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Button
                title={t('common:showHideColumns')}
                variant="text"
                aria-label={t('common:showHideColumns')}
                onClick={() => apiRef.current.showPreferences('columns')}
                sx={{
                  padding: 2,
                  justifyContent: 'center',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Visibility fontSize="medium" sx={{ color: '#00C43D' }} />
              </Button>

              <Button title={t('common:refresh')} onClick={refetch} disabled={isLoading}>
                <RefreshIcon color="action" />
              </Button>
            </Box>
          </Box>

          <DataGridPro
            getRowId={(row) => row._id}
            rows={Array.isArray(kymBusiness?.data) ? kymBusiness.data : []}
            columns={orderedColumns}
            apiRef={apiRef}
            slots={{
              columnMenu: CustomColumnMenu,
              loadingOverlay: CustomLoadingOverlay,
            }}
            hideFooter={true}
            autoHeight={true}
            sortingMode="server"
            sortModel={config.sortModel}
            onSortModelChange={(newSortModel) => saveConfig({ sortModel: newSortModel })}
            loading={isFetching || isLoading || configLoading}
            columnOrder={config.columnOrder}
            onColumnOrderChange={handleColumnOrderChange}
            pinnedColumns={config.pinnedColumns}
            onPinnedColumnsChange={(newPinnedColumns) => saveConfig({ pinnedColumns: newPinnedColumns })}
            columnVisibilityModel={visibleColumnsModel}
            onColumnVisibilityModelChange={(newModel) => saveConfig({ visibleColumns: newModel })}
          />
        </TableContainer>
      </Paper>
    </Box>
  )
}

export default KymBusiness
