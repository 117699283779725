import { Box, Typography, Grid, Divider } from '@mui/material'
import CheckDetailTitle from './components/CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckRenaper = ({ title = '', renaperCheck, level = 0 }) => {
  const details = renaperCheck?.checkDetails?.renaperData

  if (renaperCheck?.checkDetails?.found === false) {
    return (
      <Box className="detail-group" sx={{ padding: '1.5rem' }}>
        <Typography variant={variantByLevel[level] || variantByLevel[0]} sx={{ marginBottom: '1rem' }}>
          {title || 'Renaper'}
        </Typography>
        <CheckDetailTitle
          email={renaperCheck?.email}
          status={renaperCheck?.status}
          createdAt={renaperCheck?.createdAt}
          updatedAt={renaperCheck?.updatedAt}
        />
        <Typography variant="h4" sx={{ fontSize: '1rem', marginTop: '1rem' }}>
          No se encontraron datos.
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1rem' }}>
          {renaperCheck?.checkDetails?.info || 'Información no disponible'}
        </Typography>
      </Box>
    )
  }

  if (details?.codigo === 3) {
    return (
      <Box className="detail-group" sx={{ padding: '1.5rem' }}>
        <Typography variant={variantByLevel[level] || variantByLevel[0]} sx={{ marginBottom: '1rem' }}>
          {title || 'Renaper'}
        </Typography>
        <CheckDetailTitle
          email={renaperCheck?.email}
          status={renaperCheck?.status}
          createdAt={renaperCheck?.createdAt}
          updatedAt={renaperCheck?.updatedAt}
        />
        <Typography variant="h4" sx={{ fontSize: '1rem', marginTop: '1rem', color: 'red' }}>
          Error en los datos ingresados.
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1rem' }}>
          {details?.mensaje || 'Error desconocido en la consulta.'}
        </Typography>
      </Box>
    )
  }

  return (
    <Box className="detail-group" sx={{ padding: '1.5rem' }}>
      <Typography variant={variantByLevel[level] || variantByLevel[0]} sx={{ marginBottom: '1rem' }}>
        {title || 'Renaper'}
      </Typography>

      <CheckDetailTitle
        email={renaperCheck?.email}
        status={renaperCheck?.status}
        createdAt={renaperCheck?.createdAt}
        updatedAt={renaperCheck?.updatedAt}
      />

      <Typography variant="h4" sx={{ fontSize: '1.5rem', marginTop: '1.5rem', fontWeight: 'bold' }}>
        Información del Documento
      </Typography>
      <Grid container spacing={1.5} sx={{ marginTop: '0.5rem' }}>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>ID Trámite Principal:</strong> {details?.id_tramite_principal || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>ID Tarjeta Reimpresa:</strong> {details?.id_tramite_tarjeta_reimpresa || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Ejemplar:</strong> {details?.ejemplar || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Vencimiento:</strong> {details?.vencimiento || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Emisión:</strong> {details?.emision || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>CUIL:</strong> {details?.cuil || '---'}
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{ fontSize: '1.5rem', marginTop: '1.5rem', fontWeight: 'bold' }}>
        Datos Personales
      </Typography>
      <Grid container spacing={1.5} sx={{ marginTop: '0.5rem' }}>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Nombre:</strong> {details?.nombres || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Apellido:</strong> {details?.apellido || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Fecha de Nacimiento:</strong> {details?.fecha_nacimiento || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Nacionalidad:</strong> {details?.nacionalidad || '---'}
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{ fontSize: '1.5rem', marginTop: '1.5rem', fontWeight: 'bold' }}>
        Dirección
      </Typography>
      <Grid container spacing={1.5} sx={{ marginTop: '0.5rem' }}>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Calle:</strong> {details?.calle || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Número:</strong> {details?.numero || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Ciudad:</strong> {details?.ciudad || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>Provincia:</strong> {details?.provincia || '---'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            <strong>País:</strong> {details?.pais || '---'}
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{ fontSize: '1.5rem', marginTop: '1.5rem', fontWeight: 'bold' }}>
        Mensaje del Renaper
      </Typography>
      <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
        {details?.mensaje || '---'}
      </Typography>

      <Divider sx={{ margin: '1rem 0' }} />
    </Box>
  )
}

export default CheckRenaper
