import './clientConsumptions.scss'
import { Box, Paper, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFindConsumptionsQuery } from '../../../features/client/clientSlice'
import DatatableClientConsumptions from '../../../components/common/datatable/DatatableClientConsumptions'

const ClientConsumptions = () => {
  const { emailClient } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.clientConsumptions.filters)
  const { t } = useTranslation(['common'])
  const {
    data: consumptions,
    isLoading,
    isFetching,
    refetch,
  } = useFindConsumptionsQuery({
    ...queryState,
    tenant: emailClient,
  })

  return (
    <Box className="filter">
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:consumptions')}</Typography>
        </Box>
      </Box>
      <Paper elevation={0}>
        <DatatableClientConsumptions
          consumptions={consumptions}
          isLoading={isLoading || isFetching}
          refetch={refetch}
        />
      </Paper>
    </Box>
  )
}

export default ClientConsumptions
