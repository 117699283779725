import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faPerson, faBuilding, faHandshakeSimple, faExchange } from '@fortawesome/free-solid-svg-icons'
import AddCardIcon from '@mui/icons-material/AddCard'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import UndoIcon from '@mui/icons-material/Undo'
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined'
import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined'
import TransformOutlinedIcon from '@mui/icons-material/TransformOutlined'
import '../../styles/icons/iconLib.scss'
import { useSelector } from 'react-redux'

export const TableIcons = ({ type, noIcon, sizeBig }) => {
  const { t } = useTranslation(['common'])
  const { theme } = useSelector((state) => state.theme)

  switch (type) {
    case 'DEBIN':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:debin')}
          icon={noIcon ? <></> : <CurrencyExchangeOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'DEPOSIT':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:deposit')}
          icon={noIcon ? <></> : <GppMaybeOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'TRANSFER':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:transfer')}
          icon={noIcon ? <></> : <CurrencyExchangeOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'EXTERNAL_PAYMENT':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:externalPayment')}
          icon={noIcon ? <></> : <AddCardIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'WITHDRAWAL':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:withdrawal')}
          icon={noIcon ? <></> : <TransformOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'REFUND':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:refund')}
          icon={noIcon ? <></> : <CreditScoreOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'CREATED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:created')}
          icon={noIcon ? <></> : <AddCircleOutlineOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'PROCESSING':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:processing')}
          icon={noIcon ? <></> : <RotateRightIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'SUCCESSFUL':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:successful')}
          icon={noIcon ? <></> : <FontAwesomeIcon icon={faCheckDouble} />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'SENT':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:sent')}
          icon={noIcon ? <></> : <SendOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'SUSPENDED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:suspended')}
          icon={noIcon ? <></> : <PauseCircleOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'REFUNDED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:refunded')}
          icon={noIcon ? <></> : <UndoIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'DECLINED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:declined')}
          icon={noIcon ? <></> : <GppBadOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'PENDING':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:pending')}
          icon={noIcon ? <></> : <RotateRightIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'REJECTED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:rejected')}
          icon={noIcon ? <></> : <GppBadOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'CANCELED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:canceled')}
          icon={noIcon ? <></> : <GppBadOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'COMPLETED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:completed')}
          icon={noIcon ? <></> : <CheckCircleOutlinedIcon />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'ACCEPTED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:accepted')}
          icon={noIcon ? <></> : <FontAwesomeIcon icon={faCheckDouble} />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'CONSUMER':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('CONSUMER_BATCH')}
          icon={noIcon ? '' : <FontAwesomeIcon icon={faPerson} />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'BUSINESS':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('BUSINESS_BATCH')}
          icon={noIcon ? '' : <FontAwesomeIcon icon={faBuilding} />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'TRANSACTION':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('TRANSACTION_BATCH')}
          icon={noIcon ? '' : <FontAwesomeIcon icon={faHandshakeSimple} />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    case 'OTHER':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:other')}
          icon={noIcon ? '' : <FontAwesomeIcon icon={faExchange} />}
          color={theme === 'light' ? 'primary' : 'secondary'}
        />
      )
    default:
      return
  }
}
