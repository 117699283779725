import { createSlice } from '@reduxjs/toolkit'
import { clientServicesApi } from '../../services/clientServicesApi'

export const clientSlice = createSlice({
  name: 'clientConsumptions',
  initialState: {
    clientConsumptions: [],
    filters: {},
  },
  reducers: {
    updateClientConsumptions: (state, details) => {
      state.clientConsumptions = details.payload
    },
    updateClientConsumptionsFilters: (state, filters) => {
      state.filters = filters.payload
    },
    resetClientConsumptionsFilters: (state) => {
      state.filters = {}
    },
  },
})

export const { updateClientConsumptions, updateClientConsumptionsFilters, resetClientConsumptionsFilters } = clientSlice.actions

export const { useFindConsumptionsQuery, useFindConsumptionsByTenantQuery } = clientServicesApi

export default clientSlice.reducer
