import Box from '@mui/material/Box'
import Spinner from '../../../common/spinner/spinner'
import RulesLibraryTable from './RulesLibraryTable'

const RulesLibrary = ({
  rules,
  isLoading,
  setIsLoading,
  setInstanceRules,
  instanceRules,
  categories,
  setCategories,
}) => {
  return (
    <Box>
      {isLoading ? (
        <Spinner noTransform />
      ) : (
        <RulesLibraryTable
          rows={rules}
          setIsLoading={setIsLoading}
          instanceRules={instanceRules}
          setInstanceRules={setInstanceRules}
          categories={categories}
          setCategories={setCategories}
        />
      )}
    </Box>
  )
}

export default RulesLibrary
