import './listOfRuleIds.scss'
import { useState } from 'react'
import { useEffect } from 'react'
import { Box, Checkbox } from '@mui/material'
import { useSelector } from 'react-redux'
import { RuleId, RuleStatus, RuleType } from '../../rules.enums'

const ListOfRuleIds = ({ propertie, ruleParameters, setRuleParameters, isEditing }) => {
  const instanceRulesState = useSelector((state) => state.rule.instanceRules)
  const rules = useSelector((state) => state.rule.rules)
  const [selectedValue, setSelectedValue] = useState([])
  const [toBeDeleted, setToBeDeleted] = useState([])
  const [change, setChange] = useState(false)

  const filteredInstanceRules = instanceRulesState.filter(
    (instanceRule) =>
      instanceRule.ruleId !== RuleId.RG15 &&
      instanceRule.status === RuleStatus.ACTIVE &&
      rules.find((rule) => rule.templateId === instanceRule.ruleTemplate)?.type === RuleType.KYT_TRANSACTION,
  )

  useEffect(() => {
    const list = ruleParameters[propertie]
    if (Array.isArray(list)) {
      setSelectedValue(list)
      const toDelete = []
      list.forEach((item) => {
        if (instanceRulesState.filter((instance) => instance.id === item).length === 0) toDelete.push(item)
      })
      setToBeDeleted(toDelete)
    }
  }, [propertie, ruleParameters])

  const handleChange = (id) => {
    let list = [...selectedValue]
    if (list.includes(id)) {
      list = list.filter((item) => item !== id)
    } else {
      list.push(id)
    }
    setSelectedValue([...list])
    setChange(true)
  }

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      if (selectedValue.length === 0) delete all[propertie]
      else all[propertie] = selectedValue
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [selectedValue, ruleParameters, setRuleParameters])

  return (
    <Box>
      {Array.isArray(filteredInstanceRules) &&
        filteredInstanceRules?.map((item, index) => {
          return (
            <Box className="checks-list" key={index}>
              <Checkbox
                disabled={!isEditing}
                onClick={() => {
                  handleChange(item.id)
                }}
                checked={selectedValue.includes(item.id)}
              />
              <p>{`${item.maskId ?? item.ruleId} - ${item.ruleNameAlias ?? item.description} (${item.id})`}</p>
            </Box>
          )
        })}
      {Array.isArray(toBeDeleted) &&
        toBeDeleted.length !== 0 &&
        toBeDeleted?.map((item, index) => (
          <Box className="error-list" key={index}>
            <Box className="error-drawer">
              <strong>To Be Deleted</strong>
              <p>Some rule were deleted but are still configured in this rule</p>
              {item.ruleId !== 'RG-15' && (
                <Box className="checks-list">
                  <Checkbox
                    disabled={!isEditing}
                    onClick={() => {
                      handleChange(item)
                    }}
                    checked={selectedValue.includes(item)}
                  />
                  <p>{`Instance id: ${item}`}</p>
                </Box>
              )}
            </Box>
          </Box>
        ))}
    </Box>
  )
}

export default ListOfRuleIds
