import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Paper, Typography } from '@mui/material'
import { PieChart } from '@mui/x-charts/PieChart'
import Grid from '@mui/material/Unstable_Grid2'

import { useGetCaseStatisticsQuery } from '../../../features/cases/caseSlice'
import { useTranslation } from 'react-i18next'

const CasesByCategory = () => {
  const { t } = useTranslation(['messages', 'common'])

  const { period } = useSelector((state) => state.statistics)
  const { data: statisticsResponse, isLoading, isFetching } = useGetCaseStatisticsQuery({ type: 'by_category', period })
  const { data: statisticsRaw } = statisticsResponse || { data: [] }

  const statistics = useMemo(() => {
    const withoutCategory = statisticsRaw.filter(({ _id }) => _id === null || !_id)
    const totalWithoutCategory = withoutCategory.reduce((acc, item) => acc + item.total, 0)

    const statistics = statisticsRaw.filter(({ _id }) => Boolean(_id))

    return [...statistics, { _id: t('messages:statistics.alerts.categories.NOT_DEFINED'), total: totalWithoutCategory }]
  }, [t, statisticsRaw])

  const total = statistics.reduce((acc, item) => acc + item.total, 0)

  const data = useMemo(
    () =>
      statistics.map((stat) => ({
        id: stat._id || 'NO',
        value: stat.total,
        label: `${stat._id} (${stat.total})`,
        percentage: Number.parseFloat((stat.total * 100) / total).toFixed(2),
      })),
    [statistics, total],
  )

  return (
    <Grid component={Paper} container>
      <Grid xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4">{t('messages:statistics.cases.title.by_category')}</Typography>
      </Grid>
      <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <PieChart
          margin={{ top: 20, bottom: 0, left: 0, right: 280 }}
          slotProps={{
            legend: {
              direction: 'column',
            },
          }}
          series={[
            {
              data: data,
              highlightScope: { fade: 'global', highlight: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
              valueFormatter: (item) => `${item.percentage}%`,
            },
          ]}
          loading={isFetching || isLoading}
          width={550}
          height={350}
        />
      </Grid>
    </Grid>
  )
}

export default CasesByCategory
