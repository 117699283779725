import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const CheckRenaper = ({ data }) => {
  const { t } = useTranslation(['common', 'messages'])

  const items = useMemo(() => {
    if (data) {
      return Object.keys(data).map((key) => ({ key, label: data[key] }))
    }
    return []
  }, [data])

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('messages:kyc.person.section.renaper.header1')}</TableCell>
            <TableCell>{t('messages:kyc.person.section.renaper.header2')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {items.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.key.replaceAll('_', ' ')}</TableCell>
              <TableCell>{item.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CheckRenaper
