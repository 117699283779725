import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCreation, updateInstanceRules } from '../../features/rules/rulesSlice'

export const useInstanceRules = (isLoading, setIsLoading, instanceRules, setInstanceRules, isArchived, categories) => {
  const instanceRulesState = useSelector((state) => state.rule)
  const { accessToken } = useSelector((state) => state.login)
  const creationState = useSelector((state) => state.rule.creation)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoading || creationState) {
      dispatch(updateCreation(false))
      const urlGuenoInstanceRules = `${process.env.REACT_APP_BASEURL}/rules?viewMode=true${
        isArchived ? '&status=ARCHIVED' : ''
      }&categories=${categories}`

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      let allInstanceRules = []

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/instanceRules.json' : urlGuenoInstanceRules, options)
        .then((res) => res.json())
        .then((res) => {
          const { data } = res
          if (data && Array.isArray(data)) {
            const guenoData = data.map((item) => ({ ...item, gueno: true }))
            allInstanceRules.push(...guenoData)
          }
          setInstanceRules(allInstanceRules)
          dispatch(updateInstanceRules(allInstanceRules))
          setIsLoading(false)
        })
        .catch((error) => {
          setIsLoading(false)
          console.error('[HOOK: useRules] --> ', error)
        })
    }
    setInstanceRules(instanceRulesState.instanceRules)
  }, [isArchived, isLoading, categories])

  return instanceRulesState.instanceRules
}
