import { useEffect, useState } from 'react'
import { Box, Button, Fab, Modal, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import RichTextEditor from '../RichTextEditor'
import { useSummarizeCaseMutation } from '../../../../features/cases/caseSlice'
import toaster from '../../../../toaster'
import { LoadingButton } from '@mui/lab'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const prompts = [
  'summarize',
  'explanation',
  'recommendation',
  'riskAssessment',
  'report',
  'suspiciousPatterns',
  'regulatoryComplianceEvaluation',
]

const SectionCreateReport = ({
  onCloseReport,
  onCreateReport,
  onUpdateReport,
  hasPermissions,
  lastReport,
  isAssigned,
  caseIsClosed,
  caseId,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const languageState = useSelector((state) => state.language.lang)
  const { t } = useTranslation(['common', 'messages'])
  const [report, setReport] = useState(lastReport?.description || '')
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (lastReport?.description) setReport(lastReport.description)
  }, [lastReport?.description])

  const [summarizeCase, { isLoading: isSummarizingCase }] = useSummarizeCaseMutation()

  const handleSummarizeCase = async (prompt) => {
    setModalOpen(false)

    await summarizeCase({ caseId, lang: languageState, prompt }).unwrap()
    toaster.success(t('messages:creationSuccess'), { autoClose: 5000, closeButton: true })
  }

  return (
    <Box className="kyb-info">
      <Box className="reports-title-row">
        <Typography variant="title">{t('common:report')}</Typography>
        {activeServices.includes('caseManagement:idprompt') && !lastReport && (
          <LoadingButton
            color="secondary"
            size="small"
            disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
            onClick={() => {
              setModalOpen(true)
            }}
            loading={isSummarizingCase}
            endIcon={<AutoAwesomeIcon />}
          >
            {t('messages:caseManagement.prompt')}
          </LoadingButton>
        )}
      </Box>
      <Box>
        <RichTextEditor
          value={report}
          onChange={setReport}
          disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
        />
      </Box>
      <Box className="reports-row">
        {lastReport && (
          <Button
            variant="outlinedGrey"
            size="small"
            disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
            onClick={() => {
              onCloseReport({ report })
              setReport('')
            }}
          >
            {t('common:close')} {t('common:report')}
          </Button>
        )}
        <Button
          color="secondary"
          variant="contained"
          disabled={
            (!hasPermissions && !isAssigned) ||
            caseIsClosed ||
            (!activeServices.includes('caseManagement:idreports') && !!activeServices.includes('caseReports:id'))
          }
          size="small"
          onClick={() => {
            !lastReport ? onCreateReport({ report }) : onUpdateReport({ report })
          }}
        >
          {!lastReport ? `${t('common:create')}` : `${t('common:update')}`} {t('common:report')}
        </Button>
      </Box>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
      >
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{`Choose a prompt for this case`}</Typography>
            </Box>
            <Fab
              variant="close"
              onClick={() => {
                setModalOpen(false)
              }}
            >
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box-filters" sx={{ maxWidth: '35rem' }}>
            <Box className="modal-prompts-buttons">
              {prompts?.map((item, index) => {
                return (
                  <Button
                    key={item}
                    size="large"
                    variant="outlined"
                    onClick={() => {
                      handleSummarizeCase(item)
                    }}
                  >
                    {t(`messages:caseManagement.${item}`)}
                  </Button>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default SectionCreateReport
