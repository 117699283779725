import storage from 'redux-persist/lib/storage'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import transactionReducer from '../features/transactions/transactionSlice'
import userReducer from '../features/users/userSlice'
import loginReducer from '../features/auth/loginSlice'
import ruleReducer from '../features/rules/rulesSlice'
import roleReducer from '../features/roles/rolesSlice'
import userAdminReducer from '../features/usersAdmin/userAdminSlice'
import reportReducer from '../features/reports/reportSlice'
import caseReducer from '../features/cases/caseSlice'
import alertReducer from '../features/alerts/alertSlice'
import generalAlertsReducer from '../features/generalAlerts/generalAlertsSlice'
import onboardingReducer from '../features/onboarding/onboardingSlice'
import ecommerceRiskReducer from '../features/ecommerceRisk/ecommerceRiskSlice'
import govCheckReducer from '../features/govCheck/govCheckSlice'
import amlReducer from '../features/aml/amlSlice'
import amlBrReducer from '../features/aml/amlBrSlice'
import amlCryptoReducer from '../features/amlCrypto/amlCryptoSlice'
import webhooksReducer from '../features/webhooks/webhooksSlice'
import checklistsReducer from '../features/checklist/checklistSlice'
import kybReducer from '../features/kyb/kybSlice'
import languageReducer from '../features/language/languageSlice'
import themeReducer from '../features/theme/themeSlice'
import kybPersonReducer from '../features/kybPerson/kybPersonSlice'
import kybListReducer from '../features/kybLists/kybListsSlice'
import kybNotificationsReducer from '../features/kybNotifications/kybNotificationsSlice'
import listsReducer from '../features/lists/listsSlice'
import requestsToAoRReducer from '../features/requestsToAoRSlice/requestsToAoRSlice'
import riskMatrixReducer from '../features/riskMatrix/riskMatrixSlice'
import batchReducer from '../features/batch/batchSlice'
import kybCountryReducer from '../features/kyb/kybCountrySlice'
import workflowsReducer from '../features/workflows/workflowsSlice'
import paymentMethodDetailReducer from '../features/paymentMethodDetail/paymentMethodDetailSlice'
import externalPaymentMethodsReducer from '../features/externalPaymentMethods/externalPaymentMethodsSlice'
import paymentMethodDetailUsersReducer from '../features/paymentMethodDetailUsers/paymentMethodDetailUsersSlice'
import merchantsReducer from '../features/merchants/merchantsSlice'
import statisticsReducer from '../features/statistics/statisticsSlice'
import clientConsumptionsReducer from '../features/client/clientSlice'
import kymPersonsReducer from '../features/kym/kymPersonsSlice'
import kymBusinessReducer from '../features/kym/kymBusinessSlice'

import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'

import { guenoApi } from '../services/guenoApi'
import { guenoApiRv } from '../services/guenoApiRv'
import { clientServicesApi } from '../services/clientServicesApi'

const persistConfig = {
  key: 'root',
  blacklist: [
    'transaction',
    'user',
    'rule',
    'kyb',
    'kyc',
    'case',
    'generalAlerts',
    'kym',
    guenoApi.reducerPath,
    guenoApiRv.reducerPath,
    clientServicesApi.reducerPath,
  ],
  storage,
}

const reducers = combineReducers({
  transaction: transactionReducer,
  user: userReducer,
  login: loginReducer,
  rule: ruleReducer,
  role: roleReducer,
  userAdmin: userAdminReducer,
  report: reportReducer,
  case: caseReducer,
  alert: alertReducer,
  generalAlerts: generalAlertsReducer,
  onboarding: onboardingReducer,
  ecommerceRisk: ecommerceRiskReducer,
  aml: amlReducer,
  amlBr: amlBrReducer,
  amlCrypto: amlCryptoReducer,
  webhooks: webhooksReducer,
  govCheck: govCheckReducer,
  checklists: checklistsReducer,
  kyb: kybReducer,
  kybPerson: kybPersonReducer,
  language: languageReducer,
  kybList: kybListReducer,
  kybNotifications: kybNotificationsReducer,
  lists: listsReducer,
  requestsToAoR: requestsToAoRReducer,
  riskMatrix: riskMatrixReducer,
  batch: batchReducer,
  theme: themeReducer,
  kybCountry: kybCountryReducer,
  workflows: workflowsReducer,
  paymentMethodDetail: paymentMethodDetailReducer,
  paymentMethodDetailUsers: paymentMethodDetailUsersReducer,
  externalPaymentMethods: externalPaymentMethodsReducer,
  merchants: merchantsReducer,
  statistics: statisticsReducer,
  clientConsumptions: clientConsumptionsReducer,
  kymPersons: kymPersonsReducer,
  kymBusiness: kymBusinessReducer,
  [clientServicesApi.reducerPath]: clientServicesApi.reducer,
  [guenoApi.reducerPath]: guenoApi.reducer,
  [guenoApiRv.reducerPath]: guenoApiRv.reducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      inmutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        warnAfter: 128,
      },
    })
    .concat(guenoApi.middleware)
    .concat(guenoApiRv.middleware)
    .concat(clientServicesApi.middleware),
})

export default store
